import dataService from "@/services/data.service";
import Tr from "@/i18n/translation";
import router from "@/router";
import { dispatchNotification } from "@/components/notification";
import { print, formatDate } from "@/utils/globleFunction";
import { v4 as uuidv4 } from "uuid";
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";

// state
const state = {
  userProfile: null,
  loading: false,
  allItems: [],
  customer: null,
  dataInvoice: null,
  dateTimeNow: new Date(),
  receiveUSD: 0,
  receiveKHR: 0,
};

// getters
const getters = {
  loading: (state) => state.loading,
  userProfile: (state) => state.userProfile,
  profile: (state) => state.userProfile?.pos_profile,
  allItems: (state) => state.allItems,
  getDiscountAmount: (state) => state.dataInvoice?.discount_amount,
  getCartItems: (state) => state.dataInvoice?.items,
  getPostingDate: (state) => state.dataInvoice?.posting_date,
  getInvoiceNo: (state) => state.dataInvoice?.invoice_no,
  getTaxes: (state) =>
    state.dataInvoice?.taxes.find((item) => item.dpos_type === "Tax"),
  dateTimeNow: (state) => state.dateTimeNow,
  getTotal: (state) => state.dataInvoice?.total,
  getNetTotal: (state) => state.dataInvoice?.net_total,
  getGrandTotal: (state) => state.dataInvoice?.grand_total,
  getReceiveUSD: (state, getters) => {
    return state.receiveUSD;
  },
  getReceiveKHR: (state, getters) => {
    return state.receiveKHR;
  },
};

// actions
const actions = {
  setCustomer({ state, commit }, customer) {
    commit("setCustomer", customer);
  },
  clearCustomer({ state, commit }) {
    commit("clearCustomer");
  },
  getUserProfile({ state, commit }) {
    commit("setLoading", true);
    return dataService.get("check_opening_entry").then((res) => {
      let data = res.data.message;
      if (data) {
        commit("getUserProfile", data);
        commit("setLoading", false);
      } else {
        commit("setLoading", false);
      }
    });
  },
  getAllItems({ state, commit, getters }) {
    commit("setLoading", true);
    if (
      getters.profile.custom_use_browser_local_storage &&
      localStorage.items_storage
    ) {
      let items = JSON.parse(localStorage.getItem("items_storage"));
      commit("getAllItems", items);
      commit("setLoading", false);
    }
    dataService.get("get_items").then((res) => {
      let items = res.data.message;
      if (items) {
        commit("getAllItems", items);
        commit("setLoading", false);
        if (getters.profile.custom_use_browser_local_storage) {
          localStorage.setItem("items_storage", "");
          localStorage.setItem("items_storage", JSON.stringify(items));
        }
      }
    });
  },
  submitInvoice({ state, commit, dispatch }, data) {
    dataService.post("submit_invoice", data).then((res) => {
      let data = res.data.message;
      if (data) {
        commit("setDataInvoice", data);
        commit("setDateTimeNow");
        setTimeout(() => {
          print();
          dispatch("getAllItems");
          commit("setDataInvoice", null);
        }, 1000);
        dispatchNotification({
          content: `Invoice ${data.name} is Submited`,
          type: "success",
        });
        this.addresses = [];
      }
    });
  },
  setReceiveUSD({ state, commit }, receive) {
    commit("setReceiveUSD", receive);
  },
  setReceiveKHR({ state, commit }, receive) {
    commit("setReceiveKHR", receive);
  },
};

// mutations
const mutations = {
  setCustomer(state, customer) {
    state.customer = customer;
  },
  setDataInvoice(state, data) {
    state.dataInvoice = data;
  },
  clearCustomer(state) {
    state.customer = null;
  },
  getUserProfile(state, data) {
    state.userProfile = data;
  },
  getAllItems(state, items) {
    state.allItems = items;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setDateTimeNow(state) {
    state.dateTimeNow = new Date();
  },
  setReceiveUSD(state, receive) {
    state.receiveUSD = receive;
  },
  setReceiveKHR(state, receive) {
    state.receiveKHR = receive;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
