<template>
  <form class="px-5" name="form" @submit.prevent="handleSaveItem">
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
          {{ dataForm.item_name_label }}
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'item' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >Items</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'view-item' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >{{ dataForm.item_code_label }}</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        type="submit"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          class="h-5 w-5 mr-1"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
          />
          <polyline points="17 21 17 13 7 13 7 21" />
          <polyline points="7 3 7 8 15 8" />
        </svg>
        Save
      </button>
    </div>
    <div class="overflow-y-auto h-[calc(100vh_-_150px)]">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div>
          <label
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Item Image</label
          >
          <UploadImageComponent
            :dataImage="(e) => (dataForm.image = e)"
            :src="dataForm.image"
          />
        </div>
        <div
          class="col-span-2 grid grid-cols-2 sm:grid-cols-4 gap-y-3 sm:gap-6"
        >
          <InputComponent
            class="col-span-2"
            id="custom_item_code"
            label="Item Code"
            v-model="dataForm.custom_item_code"
            required
          />
          <InputComponent
            class="col-span-2"
            id="item_name"
            label="Item Name"
            v-model="dataForm.item_name"
            required
          />

          <div class="col-span-2">
            <label
              for="item_group"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Item Group <span class="text-red-400">*</span>
              <span
                class="ml-2 cursor-pointer text-secondary dark:text-primary"
                @click="isAddItemGroup = true"
                >+ Add</span
              ></label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <multiselect
                id="item_group"
                v-model="dataForm.item_group"
                placeholder=""
                label="custom_item_group_name"
                track-by="name"
                :options="dataItemGroups"
                :allow-empty="false"
                deselect-label="Can't remove this value"
              />
            </div>
          </div>
          <div class="col-span-2">
            <label
              for="uom"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >UOM <span class="text-red-400">*</span>
              <!-- <span
                class="ml-2 cursor-pointer text-secondary dark:text-primary"
                @click="isAddUOM = true"
                >+ Add</span> -->
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <multiselect
                id="uom"
                v-model="dataForm.uom"
                placeholder=""
                :options="dataUOMs"
                :allow-empty="false"
                deselect-label="Can't remove this value"
                @input="onSearchUOM"
                @open="() => getUOMs()"
              />
            </div>
          </div>

          <InputCurrencyComponent
            class="col-span-2"
            id="standard_rate"
            :prefix="getCurrencySymbol"
            label="Standard Selling Rate"
            v-model="dataForm.standard_rate"
            :options="{
              valueRange: { min: 0 },
              precision: 2,
            }"
            required
          />
          <div class="col-span-2">
            <InputCurrencyComponent
              v-if="!dataForm.is_item_bundle"
              id="rate"
              :prefix="getCurrencySymbol"
              label="Cost of Goods Sold"
              v-model="dataForm.rate"
              :options="{
                valueRange: { min: 0 },
                precision: 2,
              }"
              readonly
            />
          </div>
          <div class="space-y-4">
            <div
              v-if="getBC == 'Restaurant'"
              class="flex items-center space-x-2"
            >
              <input
                id="is_item_bundle"
                type="checkbox"
                v-model="dataForm.is_item_bundle"
                class="h-4 w-4 cursor-pointer accent-secondary"
              /><label
                accent-secondary
                for="is_item_bundle"
                class="text-sm font-medium text-gray-900 dark:text-gray-100"
                >Item Bundle</label
              >
            </div>
            <div class="flex items-center space-x-2">
              <input
                id="disabled"
                type="checkbox"
                v-model="dataForm.disabled"
                class="h-4 w-4 cursor-pointer accent-secondary"
              /><label
                for="disabled"
                class="text-sm font-medium text-gray-900 dark:text-gray-100"
                >Disabled</label
              >
            </div>
          </div>
          <div v-if="dataForm.is_item_bundle" class="col-span-4 space-y-3">
            <h1 class="text-sm font-bold text-gray-900 dark:text-gray-100">
              Items
            </h1>
            <div
              v-for="(field, index) in dataForm.items"
              class="flex space-x-4 items-end"
            >
              <div class="grid grid-cols-3 gap-6 w-full">
                <div>
                  <label
                    for="item_code"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                    >{{ index == 0 ? "Item Code" : "" }}
                    <span v-show="index == 0" class="text-red-400"
                      >*</span
                    ></label
                  >
                  <div class="relative">
                    <multiselect
                      id="item_code"
                      v-model="dataForm.items[index]"
                      placeholder=""
                      :options="dataItems"
                      label="item_code"
                      track-by="item_code"
                      :allow-empty="false"
                      deselect-label="Can't remove this value"
                      @input="onSearchItem"
                      @select="(value) => onSelectItem(value, index)"
                    >
                      <template v-slot:option="{ option }">
                        <div class="leading-5">
                          <h3>{{ option.item_code }}</h3>
                          <p class="text-gray-400">{{ option.description }}</p>
                        </div>
                      </template></multiselect
                    >
                  </div>
                </div>
                <div>
                  <label
                    for="qty"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                    >{{ index == 0 ? "Qty" : "" }}
                    <span v-show="index == 0" class="text-red-400"
                      >*</span
                    ></label
                  >
                  <div>
                    <input
                      id="qty"
                      type="Number"
                      v-model="dataForm.items[index].qty"
                      min="1"
                      required
                      class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="description"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                    >{{ index == 0 ? "Item Name" : "" }}</label
                  >
                  <div>
                    <input
                      id="description"
                      type="text"
                      v-model="dataForm.items[index].description"
                      readonly
                      required
                      class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                    />
                  </div>
                </div>
              </div>
              <button
                type="button"
                @click="dataForm.items.splice(index, 1)"
                class="m-2 dark:text-gray-100 hover:text-red-500 dark:hover:text-red-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
            <button
              type="button"
              @click="
                dataForm.items.push({ item_code: '', qyt: '', description: '' })
              "
              class="inline-flex items-center text-sm font-medium rounded-md justify-center mt-4 text-gray-800 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v12m6-6H6"
                />
              </svg>

              Add Field
            </button>
          </div>
          <div v-if="getBC == 'Retail'" class="col-span-4 space-y-3">
            <h1 class="text-sm font-bold text-gray-900 dark:text-gray-100">
              Barcodes
            </h1>
            <div
              v-for="(field, index) in dataForm.barcodes"
              class="flex space-x-4 items-end"
            >
              <div class="grid grid-cols-1 sm:grid-cols-3 gap-y-3 sm:gap-x-4 w-full">
                <div>
                  <label
                    for="barcode"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                    >{{ index == 0 ? "Barcode" : "" }}</label
                  >
                  <div>
                    <input
                      id="barcode"
                      type="text"
                      v-model="dataForm.barcodes[index].barcode"
                      class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="barcode_type"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                    >{{ index == 0 ? "Barcode Type" : "" }}
                  </label>
                  <div class="relative">
                    <multiselect
                      id="barcode_type"
                      v-model="dataForm.barcodes[index].barcode_type"
                      placeholder=""
                      :options="['EAN', 'UPC-A']"
                    />
                  </div>
                </div>

                <div>
                  <label
                    for="uom"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                    >{{ index == 0 ? "UOM" : "" }}
                  </label>
                  <div class="relative">
                    <multiselect
                      id="uom"
                      v-model="dataForm.barcodes[index].posa_uom"
                      placeholder=""
                      :options="dataUOMs"
                      :allow-empty="false"
                      deselect-label="Can't remove this value"
                      @input="onSearchUOM"
                      @select="
                        (value) => onSelectUOM(value, dataForm.barcodes[index])
                      "
                      @open="() => getUOMs()"
                    />
                  </div>
                </div>
              </div>
              <button
                type="button"
                @click="dataForm.barcodes.splice(index, 1)"
                class="m-2 dark:text-gray-100 hover:text-red-500 dark:hover:text-red-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
            <button
              type="button"
              @click="
                dataForm.barcodes.push({
                  barcode: '',
                  barcode_type: '',
                  uom: '',
                })
              "
              class="inline-flex items-center text-sm font-medium rounded-md justify-center text-gray-800 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v12m6-6H6"
                />
              </svg>

              Add Field
            </button>
          </div>
          <div v-if="getBC == 'Retail'" class="col-span-2 space-y-3">
            <h1 class="text-sm font-bold text-gray-900 dark:text-gray-100">
              Units of Measure
            </h1>
            <div
              v-for="(field, index) in dataForm.uoms"
              class="flex space-x-4 items-end"
            >
              <div class="grid grid-cols-2 gap-x-4 w-full">
                <div>
                  <label
                    for="uoms"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                    >{{ index == 0 ? "UOM" : "" }}
                  </label>
                  <div class="relative">
                    <multiselect
                      id="uoms"
                      v-model="dataForm.uoms[index].uom"
                      placeholder=""
                      :options="dataUOMs"
                      :allow-empty="false"
                      deselect-label="Can't remove this value"
                      @input="onSearchUOM"
                      @select="
                        (value) => onSelectUOM(value, dataForm.uoms[index])
                      "
                      @open="() => getUOMs()"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="conversion_factor"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                    >{{ index == 0 ? "Conversion Factor" : "" }}</label
                  >
                  <div>
                    <input
                      id="conversion_factor"
                      type="number"
                      v-model="dataForm.uoms[index].conversion_factor"
                      class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                    />
                  </div>
                </div>
              </div>
              <button
                type="button"
                @click="dataForm.uoms.splice(index, 1)"
                class="m-2 dark:text-gray-100 hover:text-red-500 dark:hover:text-red-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
            <button
              type="button"
              @click="
                dataForm.uoms.push({
                  uom: '',
                  conversion_factor: 0,
                })
              "
              class="inline-flex items-center text-sm font-medium rounded-md justify-center text-gray-800 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v12m6-6H6"
                />
              </svg>

              Add Field
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ModalComponent
    title="New Item Group"
    :isModalOpen="isAddItemGroup"
    :onToggle="handleCancelItemGroup"
    :width="578"
    :freeSpaceClose="true"
  >
    <div class="p-2 space-y-4">
      <InputComponent
        id="item_group"
        label="Name"
        v-model="itemGroup.custom_item_group_name"
        required
      />
      <div v-if="getBC == 'Restaurant'">
        <label
          for="tag"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >Kitchen/Drink Station <span class="text-red-400">*</span>
        </label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <multiselect
            id="tag"
            v-model="itemGroup.custom_kitchen_drink"
            placeholder=""
            label="kitchendrink_name"
            track-by="name"
            :options="dataKitchenDrink"
            :allow-empty="false"
            deselect-label="Can't remove this value"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end pt-2 lg:pt-4 gap-4">
      <button
        @click="handleCancelItemGroup"
        class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
      >
        Cancel
      </button>
      <button
        @click="handleSaveItemGroup"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        Save
      </button>
    </div>
  </ModalComponent>
  <ModalComponent
    title="New Item Group"
    :isModalOpen="isAddUOM"
    :onToggle="handleCancelUOM"
    :width="578"
    :freeSpaceClose="true"
  >
    <div class="p-2">
      <div>
        <label
          for="uom_name"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >UOM Name</label
        >
        <div>
          <input
            name="uom_name"
            type="text"
            v-model="uomName"
            class="w-full px-4 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end pt-2 lg:pt-4 gap-4">
      <button
        @click="handleCancelUOM"
        class="iinline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
      >
        Cancel
      </button>
      <button
        @click="handleSaveUOM"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        Save
      </button>
    </div>
  </ModalComponent>
  <SpinComponent :show="loading" />
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import Item from "@/models/item";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import UploadImageComponent from "@/components/upload/UploadImageComponent.vue";
import InputComponent from "@/components/input/InputComponent.vue";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import ItemGroup from "@/models/itemGroup";
import { mapGetters } from "vuex";
export default {
  name: "View Item",
  components: {
    SpinComponent,
    ModalComponent,
    UploadImageComponent,
    InputCurrencyComponent,
    InputComponent,
  },
  data() {
    return {
      Tr,
      dataForm: new Item(
        "",
        "",
        null,
        "Unit",
        null,
        true,
        null,
        null,
        "Moving Average",
        true,
        false,
        [],
        false,
        [],
        []
      ),
      loading: false,
      dataItemGroups: [],
      dataKitchenDrink: [],
      dataUOMs: [],
      dataItems: [],
      isAddItemGroup: false,
      isAddUOM: false,
      uomName: "",
      itemGroup: new ItemGroup("", null),
    };
  },
  created() {
    this.getItemGroup();
    this.getKitchenDrink();
    this.getUOM();
    this.getItem();
    this.getItems();
  },
  computed: {
    ...mapGetters("auth", {
      getBC: "getBC",
      getFrappeVersion: "getFrappeVersion",
      getCurrencySymbol: "getCurrencySymbol",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getItem() {
      this.loading = true;
      dataService.get("get_data_item", `key=${this.id}`).then((res) => {
        let data = res.data.message;
        this.dataForm = {
          ...data,
          item_code_label: data.custom_item_code,
          item_name_label: data.item_name,
          is_item_bundle: Boolean(data.name_item_bundle),
          disabled: Boolean(data.disabled),
        };
        this.loading = false;
      });
    },
    handleRemoveImage() {
      this.imageData = "";
      this.dataForm.image = null;
    },
    handleCancelItemGroup() {
      this.isAddItemGroup = false;
      this.itemGroup = new ItemGroup("", null);
    },
    handleCancelUOM() {
      this.isAddUOM = false;
      this.uomName = "";
    },
    handleSaveItemGroup() {
      dataService.post("create_item_group", this.itemGroup).then((res) => {
        let data = res.data.message.data;
        this.dataForm.item_group = data;
        dispatchNotification({
          content: "Create Item Group successfully",
          type: "success",
        });
        this.getItemGroup();
        this.handleCancelItemGroup();
      });
    },
    handleSaveUOM() {
      dataService.post("create_uom", { uom_name: this.uomName }).then((res) => {
        dispatchNotification({
          content: "Create UOM successfully",
          type: "success",
        });
        this.getUOM();
        this.handleCancelUOM();
      });
    },
    async handleSaveItem() {
      this.loading = true;
      if (this.dataForm.item_group) {
        if (this.dataForm.standard_rate != 0) {
          let image = "";
          if (typeof this.dataForm.image != "string" && this.dataForm.image) {
            image = await dataService
              .postFile("upload_image", this.dataForm.image)
              .then((res) => res.data.message);
          }

          await dataService
            .post("update_item", {
              ...this.dataForm,
              name: this.id,
              items: !this.dataForm.is_item_bundle ? [] : this.dataForm.items,
              image:
                typeof this.dataForm.image == "string"
                  ? this.dataForm.image
                  : image,
            })
            .then((res) => {
              dispatchNotification({
                content: "Update item successfully",
                type: "success",
              });
              this.loading = false;
              this.$router.go(-1);
            })
            .catch((err) => {
              dispatchNotification({
                content: JSON.parse(
                  JSON.parse(err.response.data._server_messages)[0]
                ).message,
                type: "warning",
              });
              this.loading = false;
            });
        } else {
          dispatchNotification({
            content: "Selling Rate is not empty!",
            type: "warning",
          });
          this.loading = false;
        }
      } else {
        dispatchNotification({
          content: "Item group is not empty!",
          type: "warning",
        });
        this.loading = false;
      }
    },
    getItemGroup() {
      dataService.get("get_item_group").then((res) => {
        this.dataItemGroups = res.data.message;
      });
    },
    getKitchenDrink() {
      dataService.get("get_kitchen_drinks").then((res) => {
        this.dataKitchenDrink = res.data.message;
      });
    },
    getUOM() {
      dataService.get("get_uom").then((res) => {
        this.dataUOMs = res.data.message;
      });
    },
    onSearchItem(event) {
      this.getItems(event.target.value);
    },
    getItems(query = "") {
      dataService.get("search_item_bundle", `query=${query}`).then((res) => {
        if (this.getFrappeVersion.split(".")[0] == "14")
          this.dataItems = res.data.results.map((item) => {
            return {
              ...item,
              item_code: item.value,
            };
          });
        else
          this.dataItems = res.data.message.map((item) => {
            return {
              ...item,
              item_code: item.value,
            };
          });
      });
    },
    onSelectItem(value, index) {
      dataService
        .get("select_item_bundle", `name=${value.value}`)
        .then((res) => {
          let data = res.data.message;
          this.dataItems = [];
          this.dataForm.items[index] = {
            item_code: data.name,
            qty: 1,
            description: data.description,
          };
          this.getItems();
        });
    },
    onSearchUOM(event) {
      this.getUOMs(event.target.value);
    },
    getUOMs(query = "") {
      dataService.get("search_uom", `query=${query}`).then((res) => {
        if (this.getFrappeVersion.split(".")[0] == "14")
          this.dataUOMs = res.data.results.map((item) => item.value);
        else this.dataUOMs = res.data.message.map((item) => item.value);
      });
    },
    onSelectUOM(value, record) {
      dataService.get("select_uom", `name=${value}`).then((res) => {
        let data = res.data.message;
        this.dataUOMs = [];
        record.uom = data.name;
        if (record.conversion_factor != undefined)
          dataService
            .get(
              "get_val_uomc",
              `uom=${data.name}&stock_uom=${this.dataForm.uom}`
            )
            .then((res) => {
              let data = res.data.message;
              if (data) record.conversion_factor = data;
              else record.conversion_factor = 0;
            });
      });
    },
  },
};
</script>
