export const dataMenus = [
  {
    id: 1,
    title: "nav.dashboard",
    slot: "dashboard",
    name_slot: "dashboard",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path fill-rule="evenodd" d="M2.25 2.25a.75.75 0 000 1.5H3v10.5a3 3 0 003 3h1.21l-1.172 3.513a.75.75 0 001.424.474l.329-.987h8.418l.33.987a.75.75 0 001.422-.474l-1.17-3.513H18a3 3 0 003-3V3.75h.75a.75.75 0 000-1.5H2.25zm6.54 15h6.42l.5 1.5H8.29l.5-1.5zm8.085-8.995a.75.75 0 10-.75-1.299 12.81 12.81 0 00-3.558 3.05L11.03 8.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l2.47-2.47 1.617 1.618a.75.75 0 001.146-.102 11.312 11.312 0 013.612-3.321z" clip-rule="evenodd" />
  </svg>
  `,
    perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
  },
  {
    id: 2,
    title: "nav.sale",
    slot: "restaurant",
    name_slot: "table",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 007.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 004.902-5.652l-1.3-1.299a1.875 1.875 0 00-1.325-.549H5.223z" />
    <path fill-rule="evenodd" d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 009.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 002.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3zm3-6a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3zm8.25-.75a.75.75 0 00-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-5.25a.75.75 0 00-.75-.75h-3z" clip-rule="evenodd" />
  </svg>  
  `,
    perBC: ["Restaurant", "Fine Dining", "Fast Food"],
  },
  {
    id: 2,
    title: "nav.sale",
    slot: "retail",
    name_slot: "retail",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 007.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 004.902-5.652l-1.3-1.299a1.875 1.875 0 00-1.325-.549H5.223z" />
    <path fill-rule="evenodd" d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 009.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 002.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3zm3-6a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3zm8.25-.75a.75.75 0 00-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-5.25a.75.75 0 00-.75-.75h-3z" clip-rule="evenodd" />
  </svg>  
  `,
    perBC: ["Retail", "Fine Dining", "Fast Food"],
  },
  {
    id: 3,
    title: "nav.report",
    slot: "report",
    name_slot: "close-shift",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path fill-rule="evenodd" d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z" clip-rule="evenodd" />
    <path fill-rule="evenodd" d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zM6 12a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V12zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM6 15a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V15zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM6 18a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V18zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z" clip-rule="evenodd" />
  </svg>  
  `,
    children: [
      {
        id: 1,
        title: "nav.closing_shift",
        slot: "close-shift",
        name_slot: "close-shift",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 2,
        title: "nav.delete_item",
        slot: "delete-item",
        name_slot: "delete-item",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food"],
      },
      {
        id: 3,
        title: "nav.sale_transactions",
        slot: "sale-transactions",
        name_slot: "sale-transactions",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 4,
        title: "nav.total_stock_summary",
        slot: "total-stock-summary",
        name_slot: "total-stock-summary",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 5,
        title: "nav.qty_on_hand",
        slot: "qty-on-hand",
        name_slot: "qty-on-hand",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 6,
        title: "nav.end_of_day",
        slot: "end-day",
        name_slot: "end-day",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
    ],
    perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
  },
  {
    id: 4,
    title: "nav.floor",
    slot: "floors",
    name_slot: "floor",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path d="M11.644 1.59a.75.75 0 01.712 0l9.75 5.25a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.712 0l-9.75-5.25a.75.75 0 010-1.32l9.75-5.25z" />
    <path d="M3.265 10.602l7.668 4.129a2.25 2.25 0 002.134 0l7.668-4.13 1.37.739a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.71 0l-9.75-5.25a.75.75 0 010-1.32l1.37-.738z" />
    <path d="M10.933 19.231l-7.668-4.13-1.37.739a.75.75 0 000 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 000-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 01-2.134-.001z" />
  </svg>
    `,
    children: [
      {
        id: 1,
        title: "nav.floor",
        slot: "floor",
        name_slot: "floor",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 2,
        title: "nav.table",
        slot: "setup-table",
        name_slot: "setup-table",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 3,
        title: "nav.qr_menu",
        slot: "qr-menu",
        name_slot: "qr-menu",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
    ],
    perBC: ["Restaurant", "Fine Dining"],
  },
  {
    id: 5,
    title: "nav.item",
    slot: "menus",
    name_slot: "setup-menu",
    icon: `<svg class="h-6 w-6"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <line x1="17" y1="10" x2="3" y2="10" />  <line x1="21" y1="6" x2="3" y2="6" />  <line x1="21" y1="14" x2="3" y2="14" />  <line x1="17" y1="18" x2="3" y2="18" /></svg>  
    `,
    children: [
      {
        id: 1,
        title: "nav.item",
        slot: "item",
        name_slot: "item",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 2,
        title: "nav.item_group",
        slot: "item-group",
        name_slot: "item-group",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 3,
        title: "nav.menu_group",
        slot: "setup-menu",
        name_slot: "setup-menu",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food"],
      },
      {
        id: 4,
        title: "nav.kitchen_station",
        slot: "kitchen-drink",
        name_slot: "kitchen-drink",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food"],
      },
      {
        id: 5,
        title: "nav.modifier",
        slot: "modifier",
        name_slot: "modifier",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food"],
      },
    ],
    perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
  },
  {
    id: 6,
    title: "nav.stock",
    slot: "stock",
    name_slot: "stock-item",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path d="M12.378 1.602a.75.75 0 0 0-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03ZM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 0 0 .372-.648V7.93ZM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 0 0 .372.648l8.628 5.033Z" />
  </svg>
  `,
    children: [
      {
        id: 2,
        title: "nav.purchase_invoice",
        slot: "purchase-invoice",
        name_slot: "purchase-invoice",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 3,
        title: "nav.uom",
        slot: "uom",
        name_slot: "uom",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 4,
        title: "nav.uom_conversion",
        slot: "uom-conversion-factor",
        name_slot: "uom-conversion-factor",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 5,
        title: "nav.supplier",
        slot: "supplier",
        name_slot: "supplier",
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 6,
        title: "nav.supplier_group",
        slot: "supplier-group",
        name_slot: "supplier-group",
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
    ],
    perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
  },
  {
    id: 6,
    title: "nav.user",
    slot: "users",
    name_slot: "user",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
  </svg>
  `,
    children: [
      {
        id: 1,
        title: "nav.user",
        slot: "user",
        name_slot: "user",
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 2,
        title: "nav.customer",
        slot: "customer",
        name_slot: "customers",
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 3,
        title: "nav.customer_group",
        slot: "customer-group",
        name_slot: "customer-group",
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
    ],
    perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
  },
  // {
  //   id: 7,
  //   title: "nav.Promotion",
  //   slot: "promotions",
  //   name_slot: "coupon",
  //   icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  //   <path d="M16.881 4.345A23.112 23.112 0 0 1 8.25 6H7.5a5.25 5.25 0 0 0-.88 10.427 21.593 21.593 0 0 0 1.378 3.94c.464 1.004 1.674 1.32 2.582.796l.657-.379c.88-.508 1.165-1.593.772-2.468a17.116 17.116 0 0 1-.628-1.607c1.918.258 3.76.75 5.5 1.446A21.727 21.727 0 0 0 18 11.25c0-2.414-.393-4.735-1.119-6.905ZM18.26 3.74a23.22 23.22 0 0 1 1.24 7.51 23.22 23.22 0 0 1-1.41 7.992.75.75 0 1 0 1.409.516 24.555 24.555 0 0 0 1.415-6.43 2.992 2.992 0 0 0 .836-2.078c0-.807-.319-1.54-.836-2.078a24.65 24.65 0 0 0-1.415-6.43.75.75 0 1 0-1.409.516c.059.16.116.321.17.483Z" />
  // </svg>
  // `,
  //   children: [
  //     {
  //       id: 1,
  //       title: "nav.Coupon",
  //       slot: "coupon",
  //       name_slot: "coupon",
  //       icon: ``,
  //       perBC: ["Retail"],
  //     },
  //     {
  //       id: 2,
  //       title: "nav.Offer",
  //       slot: "offer",
  //       name_slot: "offer",
  //       icon: ``,
  //       perBC: ["Retail"],
  //     },
  //     {
  //       id: 3,
  //       title: "nav.Loyalty Program",
  //       slot: "loyalty",
  //       name_slot: "loyalty",
  //       icon: ``,
  //       perBC: ["Retail"],
  //     },
  //   ],
  //   perBC: ["Retail"],
  // },
  {
    id: 7,
    title: "nav.setting",
    slot: "settings",
    name_slot: "setting",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path d="M17.004 10.407c.138.435-.216.842-.672.842h-3.465a.75.75 0 01-.65-.375l-1.732-3c-.229-.396-.053-.907.393-1.004a5.252 5.252 0 016.126 3.537zM8.12 8.464c.307-.338.838-.235 1.066.16l1.732 3a.75.75 0 010 .75l-1.732 3.001c-.229.396-.76.498-1.067.16A5.231 5.231 0 016.75 12c0-1.362.519-2.603 1.37-3.536zM10.878 17.13c-.447-.097-.623-.608-.394-1.003l1.733-3.003a.75.75 0 01.65-.375h3.465c.457 0 .81.408.672.843a5.252 5.252 0 01-6.126 3.538z" />
    <path fill-rule="evenodd" d="M21 12.75a.75.75 0 000-1.5h-.783a8.22 8.22 0 00-.237-1.357l.734-.267a.75.75 0 10-.513-1.41l-.735.268a8.24 8.24 0 00-.689-1.191l.6-.504a.75.75 0 10-.964-1.149l-.6.504a8.3 8.3 0 00-1.054-.885l.391-.678a.75.75 0 10-1.299-.75l-.39.677a8.188 8.188 0 00-1.295-.471l.136-.77a.75.75 0 00-1.477-.26l-.136.77a8.364 8.364 0 00-1.377 0l-.136-.77a.75.75 0 10-1.477.26l.136.77c-.448.121-.88.28-1.294.47l-.39-.676a.75.75 0 00-1.3.75l.392.678a8.29 8.29 0 00-1.054.885l-.6-.504a.75.75 0 00-.965 1.149l.6.503a8.243 8.243 0 00-.689 1.192L3.8 8.217a.75.75 0 10-.513 1.41l.735.267a8.222 8.222 0 00-.238 1.355h-.783a.75.75 0 000 1.5h.783c.042.464.122.917.238 1.356l-.735.268a.75.75 0 10.513 1.41l.735-.268c.197.417.428.816.69 1.192l-.6.504a.75.75 0 10.963 1.149l.601-.505c.326.323.679.62 1.054.885l-.392.68a.75.75 0 101.3.75l.39-.679c.414.192.847.35 1.294.471l-.136.771a.75.75 0 101.477.26l.137-.772a8.376 8.376 0 001.376 0l.136.773a.75.75 0 101.477-.26l-.136-.772a8.19 8.19 0 001.294-.47l.391.677a.75.75 0 101.3-.75l-.393-.679a8.282 8.282 0 001.054-.885l.601.504a.75.75 0 10.964-1.15l-.6-.503a8.24 8.24 0 00.69-1.191l.735.268a.75.75 0 10.512-1.41l-.734-.268c.115-.438.195-.892.237-1.356h.784zm-2.657-3.06a6.744 6.744 0 00-1.19-2.053 6.784 6.784 0 00-1.82-1.51A6.704 6.704 0 0012 5.25a6.801 6.801 0 00-1.225.111 6.7 6.7 0 00-2.15.792 6.784 6.784 0 00-2.952 3.489.758.758 0 01-.036.099A6.74 6.74 0 005.251 12a6.739 6.739 0 003.355 5.835l.01.006.01.005a6.706 6.706 0 002.203.802c.007 0 .014.002.021.004a6.792 6.792 0 002.301 0l.022-.004a6.707 6.707 0 002.228-.816 6.781 6.781 0 001.762-1.483l.009-.01.009-.012a6.744 6.744 0 001.18-2.064c.253-.708.39-1.47.39-2.264a6.74 6.74 0 00-.408-2.308z" clip-rule="evenodd" />
  </svg>`,
    children: [
      {
        id: 1,
        title: "nav.setting",
        slot: "setting",
        name_slot: "setting",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
      {
        id: 2,
        title: "nav.payment",
        slot: "payments",
        name_slot: "payment",
        icon: ``,
        perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
      },
    ],
    perBC: ["Restaurant", "Fine Dining", "Fast Food", "Retail"],
  },
];
