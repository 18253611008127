export default class Register {
  constructor(
    first_name,
    last_name,
    email,
    phone,
    company_name,
    custom_business_categories,
    currency,
    password
  ) {
    this.password = password;
    this.currency = currency;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.phone = phone;
    this.company_name = company_name;
    this.custom_business_categories = custom_business_categories;
  }
}
