<template>
  <div
    class="h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]"
  >
    <div
      class="absolute block sm:hidden top-0 left-0 dark:text-gray-200 bg-white dark:bg-slate-900 p-2.5"
    >
      <span
        class="text-gray-400 hover:text-gray-900 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-white cursor-pointer"
        @click="isDrawerOpenMenu = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
          />
        </svg>
      </span>
    </div>
    <div class="hidden sm:block">
      <div
        class="h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)] relative flex flex-col items-center text-gray-600 bg-white dark:text-gray-100 dark:bg-slate-900 shadow"
      >
        <Menu
          as="div"
          v-for="item in dataMenus"
          :key="item.id"
          class="relative"
          v-slot="{ isClose }"
        >
          <div>
            <div
              v-if="
                item.perBC.includes(getBC) &&
                item.perBC.includes(getBusinessType)
              "
              class="flex flex-col items-center py-0.5 lg:py-1.5"
            >
              <MenuButton>
                <button
                  type="button"
                  @click="
                    () => {
                      if (!item.children)
                        $router.push(Tr.i18nRoute({ name: item.name_slot }));
                    }
                  "
                  class="text-center duration-500 w-[38px] lg:w-16 p-1.5 rounded-lg text-[8px] lg:text-xs overflow-hidden hover:bg-gray-200 dark:hover:text-white dark:hover:bg-transparent"
                  :class="[
                    currentRoute == item.slot ||
                    currentRouteName == item.name_slot
                      ? 'text-primary dark:text-white'
                      : 'text-gray-800 dark:text-gray-300/80',
                  ]"
                >
                  <span class="flex flex-col items-center">
                    <span v-html="item.icon"></span>
                  </span>
                  {{ $t(item.title) }}
                </button>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute -right-56 top-0 w-56 z-10 origin-left divide-y divide-gray-300 dark:divide-gray-500 rounded-md bg-white dark:bg-slate-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                v-show="item.children"
              >
                <div class="p-1">
                  <MenuItem v-for="i in item.children" :key="i.id">
                    <div>
                      <button
                        type="button"
                        v-if="
                          i.perBC.includes(getBC) &&
                          i.perBC.includes(getBusinessType)
                        "
                        @click="
                          $router.push(Tr.i18nRoute({ name: i.name_slot }))
                        "
                        class="text-gray-700 duration-400 rounded-md dark:text-gray-100 p-3 text-sm w-full text-left"
                        :class="[
                          currentRoute == i.slot ||
                          currentRouteName == i.name_slot
                            ? 'text-primary bg-primary/10 dark:text-gray-100 dark:bg-secondary'
                            : 'hover:bg-gray-200 dark:hover:bg-slate-700',
                        ]"
                      >
                        {{ $t(i.title) }}
                      </button>
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </div>
        </Menu>
        <div class="absolute bottom-2 lg:bottom-4">
          <div
            v-if="currentRoute == 'restaurant'"
            class="flex flex-col items-center py-0.5"
          >
            <button
              @click="handleClosing"
              class="text-center w-[38px] lg:w-16 p-1 lg:p-2 rounded text-[8px] lg:text-xs overflow-hidden"
            >
              <span class="flex flex-col items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
                  />
                </svg>
              </span>
              {{ $t("nav.closing_shift") }}
            </button>
          </div>
          <div
            v-if="currentRouteName == 'retail'"
            class="flex flex-col items-center py-0.5"
          >
            <button
              @click="handleClosing"
              class="text-center w-[38px] lg:w-16 p-1 lg:p-2 rounded text-[8px] lg:text-xs overflow-hidden"
            >
              <span class="flex flex-col items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
                  />
                </svg>
              </span>
              {{ $t("nav.closing_shift") }}
            </button>
          </div>
          <div class="flex flex-col items-center py-0.5">
            <button
              class="text-center w-[38px] lg:w-16 p-1 lg:p-2 rounded text-[8px] lg:text-xs overflow-hidden"
              @click="this.isLogoutMessage = true"
            >
              <span class="flex flex-col items-center">
                <svg
                  class="h-6 w-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                  <polyline points="16 17 21 12 16 7" />
                  <line x1="21" y1="12" x2="9" y2="12" />
                </svg>
              </span>
              {{ $t("nav.logout") }}
            </button>
          </div>
          <div>
            <span class="flex flex-col items-center text-xs text-gray-400">{{
              user?.versions
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <ModalComponent
      :title="$t('nav.logout')"
      :isModalOpen="isLogoutMessage"
      :onToggle="() => (this.isLogoutMessage = false)"
      :width="378"
      :freeSpaceClose="true"
    >
      <div class="p-2">
        <h1 class="dark:text-gray-100">
          {{ $t("do_you_want_logout") }}
        </h1>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          @click="logout"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-primary text-white font-medium rounded-md justify-center"
        >
          {{ $t("nav.logout") }}
        </button>
      </div>
    </ModalComponent>
    <DrawerComponent
      :isDrawerOpen="isDrawerOpenMenu"
      :isDrawerClose="() => (this.isDrawerOpenMenu = false)"
      :placement="'left'"
    >
      <div
        class="h-28 w-full flex justify-center items-center border-b dark:border-slate-700"
      >
        <router-link :to="Tr.i18nRoute({ name: 'dashboard' })">
          <img
            src="@/assets/image/Logo.png"
            alt="Logo"
            class="cursor-pointer w-24"
          />
        </router-link>
      </div>
      <!--  -->
      <div class="w-full px-2 mt-2 overflow-y-auto h-[calc(100vh_-_215px)]">
        <div v-for="item in dataMenus">
          <div
            v-if="
              item.perBC.includes(getBC) && item.getBC.includes(getBusinessType)
            "
          >
            <router-link
              v-if="!item.children"
              :to="Tr.i18nRoute({ name: item.name_slot })"
              class="flex w-full items-center space-x-1 rounded-lg px-4 py-3 text-left text-sm font-medium focus:outline-none focus-visible:ring"
              :class="[
                $route.name == item.name_slot
                  ? 'text-primary dark:text-white'
                  : 'text-gray-800 dark:text-gray-300/80',
              ]"
            >
              <span v-html="item.icon"></span>
              <span>{{ $t(item.title) }}</span>
            </router-link>

            <Disclosure v-else v-slot="{ open }">
              <DisclosureButton
                class="flex w-full justify-between rounded-lg px-4 py-3 text-left text-sm font-medium dark:text-gray-300 focus:outline-none focus-visible:ring"
                :class="[
                  currentRoute == item.slot
                    ? 'text-primary dark:text-white'
                    : 'text-gray-800 dark:text-gray-300/80',
                ]"
              >
                <div class="flex items-center space-x-1">
                  <span v-html="item.icon"></span>
                  <span>{{ $t(item.title) }}</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="h-5 w-5 duration-100"
                  :class="open ? 'rotate-180 transform' : 'rotate-90'"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </DisclosureButton>
              <DisclosurePanel>
                <div class="px-4">
                  <div v-for="i in item.children">
                    <button
                      type="button"
                      v-if="
                        i.perBC.includes(getBC) &&
                        i.perBC.includes(getBusinessType)
                      "
                      @click="$router.push(Tr.i18nRoute({ name: i.name_slot }))"
                      class="w-auto py-2 px-3 text-xs lg:text-xs"
                      :class="[
                        $route.name == i.name_slot
                          ? 'text-primary dark:text-white'
                          : 'text-gray-800 dark:text-gray-300/80',
                      ]"
                    >
                      {{ $t(i.title) }}
                    </button>
                  </div>
                </div>
              </DisclosurePanel>
            </Disclosure>
          </div>
        </div>
      </div>
      <div
        class="absolute bottom-16 lg:bottom-4 border-t dark:border-slate-700 w-full"
        v-if="currentRoute == 'restaurant'"
      >
        <div
          class="w-full text-center text-xs overflow-hidden p-2 dark:text-gray-300 cursor-pointer"
          @click="handleClosing"
        >
          Close Sift
        </div>
      </div>
      <div
        class="absolute bottom-16 lg:bottom-4 border-t dark:border-slate-700 w-full"
        v-if="currentRouteName == 'retail'"
      >
        <div
          class="w-full text-center text-xs overflow-hidden p-2 dark:text-gray-300 cursor-pointer"
          @click="handleClosing"
        >
          Close Sift
        </div>
      </div>
      <div
        class="absolute bottom-2 lg:bottom-4 border-t dark:border-slate-700 w-full"
      >
        <div
          class="w-full text-center text-xs overflow-hidden p-2 dark:text-gray-300 cursor-pointer"
          @click="this.isLogoutMessage = true"
        >
          {{ $t("nav.logout") }}
        </div>
        <div>
          <span class="text-xs text-gray-400 px-2">v {{ user?.versions }}</span>
        </div>
      </div>
    </DrawerComponent>
    <ModalComponent
      title="Close Register"
      :isModalOpen="isClose"
      :onToggle="handleModalCloseClosingEntry"
      :width="578"
      :freeSpaceClose="false"
    >
      <div class="p-2 lg:p-4">
        <div class="flex justify-center">
          <div class="text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-20 h-20 text-gray-400"
            >
              <path
                fill-rule="evenodd"
                d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                clip-rule="evenodd"
              />
            </svg>
            <h1 class="dark:text-gray-100">{{ fullName }}</h1>
          </div>
        </div>
        <div class="mt-8 dark:text-gray-100">
          <!-- <h1 class="text-center font-bold">{{ $t("mode_of_payment") }}</h1> -->
          <div class="mt-2" v-for="(item, index) in modeOfPayment">
            <h1 class="font-medium">{{ item.mode_of_payment }}</h1>
            <div class="grid grid-cols-2 gap-6">
              <InputCurrencyComponent
                :id="`mode_of_payment${index}`"
                :prefix="getCurrencySymbol"
                v-model="item.closing_amount_usd"
                :options="{
                  valueRange: { min: 0 },
                }"
              />
              <InputCurrencyComponent
                :id="`mode_of_payment${index}`"
                prefix="៛"
                v-model="item.closing_amount_khr"
                :options="{
                  valueRange: { min: 0 },
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4">
        <button
          type="button"
          @click="handleSaveClosing"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          {{ $t("save") }}
        </button>
      </div>
    </ModalComponent>
    <div v-if="isClosePrint">
      <PrintComponent>
        <CloseShiftDetailContentView :data="dataClosePrint" />
      </PrintComponent>
    </div>
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import store from "@/store";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { mapActions, mapGetters } from "vuex";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import { dispatchNotification } from "@/components/notification";
import DrawerComponent from "@/components/drawer/DrawerComponent.vue";
import { dataMenus } from "./DataMenus";
import PrintComponent from "@/components/print/PrintComponent.vue";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/vue";
import dataService from "@/services/data.service";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import CloseShiftDetailContentView from "../view/app/report/close/CloseShiftDetailContentView.vue";

export default {
  components: {
    ModalComponent,
    SpinComponent,
    DrawerComponent,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    InputCurrencyComponent,
    CloseShiftDetailContentView,
    PrintComponent,
  },
  data() {
    return {
      Tr,
      isLogoutMessage: false,
      loading: false,
      isDrawerOpenMenu: false,
      dataMenus,
      dataClose: {},
      isClose: false,
      isClosePrint: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path.split("/")[2];
    },
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters("auth", {
      loggedIn: "isAuthenticated",
      opened: "getIsOpened",
      getCurrencySymbol: "getCurrencySymbol",
      modeOfPayment: "getModeOfPayment",
      getDataOpening: "getDataOpening",
      getBusinessType: "getBusinessType",
      getCurrencyExchange: "getCurrencyExchange",
      getBC: "getBC",
      dataClosePrint: "getDataClosePrint",
    }),
    user() {
      return store.state?.auth?.user;
    },
    fullName() {
      return this.user?.full_name?.replace(/\b\w/g, (match) =>
        match?.toUpperCase()
      );
    },
  },
  watch: {
    opened(newData) {
      if (!newData) this.isClose = false;
    },
    $route() {
      this.isDrawerOpenMenu = false;
      this.isClose = false;
      this.isClosePrint = false;
      this.loading = false;
    },
  },
  methods: {
    ...mapActions("auth", {
      saveClosing: "saveClosing",
      clearAmount: "clearAmount",
      logout: "logout",
    }),
    handleSaveClosing() {
      this.isClosePrint = true;
      this.loading = true;
      let sum_usd = this.modeOfPayment.reduce(
        (a, b) => a + b.closing_amount_usd,
        0
      );
      let sum_khr = this.modeOfPayment.reduce(
        (a, b) => a + b.closing_amount_khr,
        0
      );
      let sum = sum_usd + sum_khr / this.getCurrencyExchange;
      if (sum > 0) {
        this.dataClose.payment_reconciliation.forEach((item) => {
          let exit = this.modeOfPayment.find(
            (payment) => payment.mode_of_payment === item.mode_of_payment
          );
          if (exit) {
            item.closing_amount_usd = exit.closing_amount_usd;
            item.closing_amount_khr = exit.closing_amount_khr;
          }
        });
        this.saveClosing(this.dataClose);
        this.clearAmount();
      } else {
        dispatchNotification({
          content: "Please input mode of payment!",
          type: "warning",
        });
        this.loading = false;
      }
    },
    handleClosing() {
      this.getDataClosing();
      this.clearAmount();
    },
    handleModalCloseClosingEntry() {
      this.isClose = false;
    },
    getDataClosing() {
      this.loading = true;
      dataService
        .post("make_closing_shift_from_opening", this.getDataOpening)
        .then((res) => {
          let data = res.data.message;
          this.dataClose = data;
          this.isClose = true;
          this.loading = false;
        });
    },
  },
};
</script>
