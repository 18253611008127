import AuthService from "@/services/auth.service";
import router from "@/router";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import { baseAPI } from "@/services/baseAPI";
import dataService from "@/services/data.service";
import { print } from "@/utils/globleFunction";

const state = {
  status: { loggedIn: false, opened: false, isHideSignup: false },
  user: null,
  dataClosePrint: null,
};

const getters = {
  getEmail: (state) => state.user?.email,
  getHideSignup: (state) => state.status.isHideSignup,
  getDataClosePrint: (state) => state.dataClosePrint,
  getBC: (state) => state.user?.custom_business_categories,
  getFrappeVersion: (state) => state.user?.frappe_versions,
  getUserImage: (state) => state.user?.user_image,
  isUsePrinter: (state) => state.user?.custom_use_printer,
  isUseKDS: (state) => state.user?.custom_use_kds,
  getLogo: (state) => state.user?.logo,
  getQRCode: (state) => state.user?.qr,
  getVATTin: (state) => state.user?.vat_tin,
  getFooter1: (state) => state.user?.footer1,
  getFooter2: (state) => state.user?.footer2,
  getFooter3: (state) => state.user?.footer3,
  getDisplayName: (state) => state.user?.display_name,
  getAddress: (state) => state.user?.address,
  getCustomer: (state) => state.user?.customer,
  getAllowCreateUser: (state) => state.user?.allow_create_user,
  getUserServices: (state) => state.user?.items_service,
  getDiscountRate: (state) => state.user?.discount_rate,
  getTaxType: (state) => state.user?.tax_types,
  getModeOfPayment: (state) => state.user?.mode_of_payments,
  getDataOpening: (state) => state.user?.opening_shift,
  getPOSEntry: (state) => state.user?.opening_shift.name,
  isAuthenticated: (state) => state.status.loggedIn,
  getIsOpened: (state) => state.status.opened,
  getCurrencySymbol: (state) => state.user.currency_symbol,
  getTax: (state, getters) => {
    let tax = state.user?.taxes_and_charges?.find((e) => e.dpos_type === "Tax");
    return getters.getTaxType === "Tax 10%" ? tax.dpos_rate || tax.rate : 0;
  },
  getCharge: (state, getters) => {
    let charge = state.user?.taxes_and_charges?.find(
      (e) => e.dpos_type === "Service Charge"
    )?.dpos_rate;
    return charge ? charge : 0;
  },
  getCurrencyExchange: (state, getters) => {
    let exchange_rate = state.user?.currency_exchange.exchange_rate;
    return exchange_rate ? exchange_rate : 0;
  },
  getTaxAndCharge: (state) => {
    return state.user?.taxes_and_charges;
  },
  getPOSProfile: (state) => {
    return state.user?.pos_profile;
  },
  getUserRoles: (state) => {
    return state.user?.roles;
  },
  getGuestCount: (state) => state.user?.guest_count,
  getBusinessType: (state) => state.user?.business_types,
};

const actions = {
  clearAmount({ commit, dispatch }) {
    commit("clearAmount");
  },
  saveClosing({ commit, dispatch }, data) {
    return AuthService.createClosing(data).then(
      (res) => {
        dataService.get("get_closing_detail", `key=${res}`).then((res) => {
          let data = res.data.message;
          if (data) {
            setTimeout(() => {
              print();
              router.replace({
                ...Tr.i18nRoute({ name: "dashboard" }),
              });
              commit("setStatusOpen", false);
            }, 100);
            commit("setDataClosePrint", data);
          }
        });
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  saveOpening({ state, commit, getters }, data) {
    const user = state.user;
    return AuthService.createOpening(data, user.uid).then(
      (res) => {
        router.replace({
          ...Tr.i18nRoute({
            name: getters.getBC == "Restaurant" ? "table" : "retail",
          }),
        });
        commit("userProfileSuccess", res.data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  getUserProfile({ commit }) {
    return AuthService.getUserProfile().then(
      (res) => {
        if (!res.roles.includes("Cashier User"))
          commit("userProfileSuccess", { ...res, opened: true });
        else {
          commit("userProfileSuccess", res);
        }
        baseAPI.defaults.headers.common["X-Frappe-CSRF-Token"] = res.csrf_token;
        return Promise.resolve(res);
      },
      (err) => {
        commit("userProfileFailure");
        return Promise.reject(err);
      }
    );
  },
  login({ commit }, user) {
    return AuthService.login(user).then((res) => {
      let { roles, opened } = res.data;
      if (!roles.includes("Cashier User")) {
        if (roles.includes("Service User"))
          router.replace({
            ...Tr.i18nRoute({ name: "table" }),
          });
        else if (roles.includes("Kitchen User"))
          router.replace(
            Tr.i18nRoute({
              name: "service-kitchen-drink",
            })
          );
        commit("loginSuccess", { ...res.data, opened: true });
      } else {
        router.replace({
          ...Tr.i18nRoute({ name: "dashboard" }),
        });
        commit("loginSuccess", res.data);
      }
      return Promise.resolve(res);
    });
  },
  logout({ commit }) {
    return AuthService.logout().then(
      (res) => {
        router.replace(Tr.i18nRoute({ name: "login" }));
        commit("logout");
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  verifyToken({ commit }) {
    return AuthService.verifyToken().then(
      (res) => {
        commit("verifyToken", res);
        return Promise.resolve(res);
      },
      (err) => {
        let error = err.response.data.message;
        if (error)
          dispatchNotification({
            content: error,
            type: "warning",
          });
        commit("verifyToken", { status: false, is_hide_signup: false });
        return Promise.reject(err);
      }
    );
  },
  verifyOpenShift({ commit }) {
    return AuthService.getUserProfile().then(
      (res) => {
        commit("userProfileSuccess", res);
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
};
const mutations = {
  verifyToken(state, value) {
    state.status.loggedIn = value.status;
    state.status.isHideSignup = value.is_hide_signup;
  },
  setStatusOpen(state, data) {
    state.status.opened = data;
  },
  loginSuccess(state, res) {
    state.status.loggedIn = true;
    state.status.opened = res.opened;
    state.user = res;
  },
  loginFailure(state) {
    state.status.loggedIn = false;
  },
  setDataClosePrint(state, data) {
    state.dataClosePrint = data;
  },
  userProfileSuccess(state, res) {
    state.status.loggedIn = true;
    state.status.opened = res.opened;
    state.user = res;
  },
  userProfileFailure(state, res) {
    state.status.loggedIn = false;
    state.status.opened = false;
    state.user = null;
  },
  logout(state) {
    state.status.loggedIn = false;
  },
  clearAmount(state) {
    state.user.mode_of_payments = state.user.mode_of_payments.map((item) => {
      return {
        ...item,
        closing_amount_usd: 0,
        closing_amount_khr: 0,
      };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
