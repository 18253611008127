<template>
  <div class="px-5">
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <div class="flex items-center space-x-2">
          <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
            {{ dataForm.supplier }}
          </h1>
          <span
            class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset"
            :class="
              dataForm.status == 'Paid'
                ? 'bg-green-50 text-secondary ring-secondary/20'
                : 'bg-red-50 text-red-700 ring-red-600/10'
            "
            ><span
              class="bg-gray-500 rounded-full h-1 w-1 mr-1"
              :class="
                dataForm.status == 'Paid' ? ' bg-secondary ' : 'bg-red-700'
              "
            ></span
            >{{ dataForm.status }}</span
          >
        </div>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'purchase-invoice' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white ellipsis-line line-clamp-1"
                  >Purchase Invoice</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <div
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                >
                  {{ dataForm.supplier }}
                </div>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        v-if="dataForm.status == 'Paid'"
        type="button"
        @click="handleCancel"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-gray-200 dark:bg-slate-700 dark:text-white font-medium rounded-md justify-center"
      >
        Cancel
      </button>
      <button
        v-if="dataForm.status == 'Cancelled' && !dataForm.is_doc_amended"
        type="button"
        @click="handleAmend"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        Amend
      </button>
    </div>
    <div class="overflow-y-auto h-[calc(100vh_-_150px)]">
      <div class="grid grid-cols-2 sm:grid-cols-4 gap-3 sm:gap-6">
        <InputComponent
          class="col-span-2"
          id="supplier"
          label="Supplier"
          v-model="dataForm.supplier"
          required
          readonly
        />
        <InputComponent
          class="col-span-2"
          id="posting_date"
          label="Posting Date"
          v-model="dataForm.posting_date"
          required
          readonly
        />
        <InputComponent
          class="col-span-2"
          v-show="dataForm.amended_from"
          id="amended_from"
          label="Amended From"
          v-model="dataForm.amended_from"
          readonly
        />
        <div class="col-span-4 space-y-4">
          <h1 class="text-sm font-bold text-gray-900 dark:text-gray-100">
            Items
          </h1>
          <div
            v-for="(field, index) in dataForm.items"
            class="flex space-x-4 items-end"
          >
            <div
              class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 gap-3 sm:gap-6 w-full"
            >
              <InputComponent
                id="item_code"
                :label="index == 0 ? 'Item Code' : ''"
                v-model="dataForm.items[index].item_code"
                required
                readonly
              />
              <InputComponent
                id="description"
                :label="index == 0 ? 'Item Name' : ''"
                v-model="dataForm.items[index].description"
                required
                readonly
              />
              <InputComponent
                id="uom"
                :label="index == 0 ? 'UOM' : ''"
                v-model="dataForm.items[index].uom"
                required
                readonly
              />
              <InputComponent
                id="qty"
                type="Number"
                :label="index == 0 ? 'Qty' : ''"
                required
                readonly
                v-model="dataForm.items[index].qty"
              />
              <InputCurrencyComponent
                id="rate"
                :prefix="getCurrencySymbol"
                :label="index == 0 ? 'Rate' : ''"
                required
                readonly
                v-model="dataForm.items[index].rate"
              />
              <InputCurrencyComponent
                id="amount"
                :prefix="getCurrencySymbol"
                :label="index == 0 ? 'Amount' : ''"
                required
                readonly
                v-model="dataForm.items[index].amount"
              />
            </div>
          </div>
        </div>
        <div
          class="col-span-4 sm:flex items-center justify-between pt-4 space-y-3 border-t dark:border-gray-600"
        >
          <InputComponent
            class="sm:w-1/3"
            id="total_qty"
            label="Total Quantity"
            v-model="dataForm.total_qty"
            readonly
          />
          <InputCurrencyComponent
            class="sm:w-1/3"
            id="grand_total"
            :prefix="getCurrencySymbol"
            label="Grand Total"
            v-model="dataForm.grand_total"
            readonly
          />
        </div>
      </div>
    </div>
  </div>
  <SpinComponent :show="loading" />
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import PurchaseInvoice from "@/models/purchase_invoice";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import InputComponent from "@/components/input/InputComponent.vue";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import { formatDate, formatTime } from "@/utils/globleFunction";

export default {
  name: "View Item",
  components: {
    SpinComponent,
    ModalComponent,
    InputCurrencyComponent,
    InputComponent,
  },
  data() {
    return {
      Tr,
      dataForm: new PurchaseInvoice(null, "", "", false, []),
      loading: false,
    };
  },
  created() {
    this.getPurchaseInvoice();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getPurchaseInvoice() {
      this.loading = true;
      dataService.get("get_purchase_invoice", `key=${this.id}`).then((res) => {
        let data = res.data.message;
        this.dataForm = {
          ...data,
          posting_date:
            formatDate(data.posting_date) + " " + formatTime(data.posting_time),
          items: data.items.map((item) => {
            return {
              ...item,
              item_code: item.item_code.split(" - ").slice(-1)[0],
            };
          }),
        };
        this.loading = false;
      });
    },
    handleCancel() {
      this.loading = true;
      dataService
        .post("cancel_purchase_invoice", { name: this.dataForm.name })
        .then((res) => {
          let data = res.data.message;
          this.dataForm.status = data.status;
          this.dataForm.is_doc_amended = data.is_doc_amended;
          this.loading = false;
        });
    },
    handleAmend() {
      this.$router.replace(
        Tr.i18nRoute({
          name: "create-purchase-invoice",
          query: { name: this.dataForm.name },
        })
      );
    },
  },
};
</script>
