<template>
  <!-- <div>
    <v-dialog v-model="isCancelInvoice" max-width="330">
      <v-card>
        <v-card-title class="text-h5">
          <span class="headline primary--text">{{ __("Cancel Current Invoice ?") }}</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="handleCancelInvoice">
            {{ __("Cancel") }}
          </v-btn>
          <v-btn color="warning" @click="isCancelInvoice = false">
            {{ __("Back") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card style="max-height: 70vh; height: 70vh" class="cards my-0 py-0 grey lighten-5">
      <v-row align="center" class="items px-2 py-1">
        <v-col v-if="pos_profile.custom_allow_create_sales_order" cols="9" class="pb-2 pr-0">
          <Customer></Customer>
        </v-col>
        <v-col v-if="!pos_profile.custom_allow_create_sales_order" cols="12" class="pb-2">
          <Customer></Customer>
        </v-col>
        <v-col v-if="pos_profile.custom_allow_create_sales_order" cols="3" class="pb-2">
          <v-select
            dense
            hide-details
            outlined
            color="primary"
            background-color="white"
            :items="invoiceTypes"
            :label="frappe._('Type')"
            v-model="invoiceType"
            :disabled="invoiceType == 'Return'"
          ></v-select>
        </v-col>
      </v-row>

      <v-row
        align="center"
        class="items px-2 py-1 mt-0 pt-0"
        v-if="pos_profile.custom_use_delivery_charges"
      >
  <v-col cols="8" class="pb-0 mb-0 pr-0 pt-2">
   <v-autocomplete
            dense
            clearable
            outlined
            color="primary"
            :label="frappe._('Delivery Charges')"
            v-model="selcted_delivery_charges"
            :items="delivery_charges"
            item-text="name"
            return-object
            background-color="white"
            hide-details
            :filter="deliveryChargesFilter"
            @change="update_delivery_charges()"
            no-data-text="Don't see your Delivery Charge! Please create new. "
          >
            <v-list-item-title slot="append-item" class="black--text">
              <button
                type="submit"
                class="create_delivery_charge_buton"
                @click="create_delivery_charge"
              >
              <v-icon
      size="large"
      class="mdi-color"
    >
      mdi-plus
    </v-icon>
      
      Create New
              </button>

     

            </v-list-item-title>

            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text subtitle-1"
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="`Rate: ${data.item.rate}`"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="4" class="pb-0 mb-0 pt-2">
          <v-text-field
            dense
            outlined
            color="primary"
            :label="frappe._('Delivery Charges Rate')"
            background-color="white"
            hide-details
            :value="formtCurrency(delivery_charges_rate)"
            :prefix="pos_profile.currency"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>



      <v-row
        align="center"
        class="items px-2 py-1 mt-0 pt-2"
        v-if="pos_profile.custom_allow_change_posting_date"
      >
        <v-col v-if="pos_profile.custom_allow_change_posting_date" cols="4" class="pb-2">
          <v-menu
            ref="invoice_posting_date"
            v-model="invoice_posting_date"
            :close-on-content-click="false"
            transition="scale-transition"
            dense
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="posting_date"
                :label="frappe._('Posting Date')"
                readonly
                outlined
                dense
                background-color="white"
                clearable
                color="primary"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="posting_date"
              no-title
              scrollable
              color="primary"
              :min="frappe.datetime.add_days(frappe.datetime.now_date(true), -7)"
              :max="frappe.datetime.add_days(frappe.datetime.now_date(true), 7)"
              @input="invoice_posting_date = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <div class="my-0 py-0 overflow-y-auto" style="max-height: 60vh">
        <template @mouseover="style = 'cursor: pointer'">
          <v-data-table
            :headers="items_headers"
            :items="items"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            item-key="posa_row_id"
            class="elevation-1"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:item.qty="{ item }">{{ formtFloat(item.qty) }}</template>
            <template v-slot:item.rate="{ item }">{{
              formtCurrency(item.rate)
            }}</template>
            <template v-slot:item.amount="{ item }">{{
              formtCurrency(item.qty * item.rate)
            }}</template>
            <template v-slot:item.posa_is_offer="{ item }">
              <v-simple-checkbox
                :value="!!item.posa_is_offer || !!item.posa_is_replace"
                disabled
              ></v-simple-checkbox>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="1">
                    <v-btn
                      :disabled="!!item.posa_is_offer || !!item.posa_is_replace"
                      icon
                      color="error"
                      @click.stop="handleRemoveItem(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-btn
                      :disabled="!!item.posa_is_offer || !!item.posa_is_replace"
                      icon
                      color="secondary"
                      @click.stop="subtract_one(item)"
                    >
                      <v-icon>mdi-minus-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      :disabled="!!item.posa_is_offer || !!item.posa_is_replace"
                      icon
                      color="secondary"
                      @click.stop="add_one(item)"
                    >
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Item Code')"
                      background-color="white"
                      hide-details
                      v-model="item.item_code"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('QTY')"
                      background-color="white"
                      hide-details
                      v-model.number="item.qty"
                      type="number"
                      @change="handleChangeQTY(item, $event)"
                      :disabled="!!item.posa_is_offer || !!item.posa_is_replace"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      dense
                      background-color="white"
                      :label="frappe._('UOM')"
                      v-model="item.uom"
                      :items="item.item_uoms"
                      outlined
                      item-text="uom"
                      item-value="uom"
                      hide-details
                      @change="handleChangeUOM(item, $event)"
                      :disabled="
                        !!invoice_doc?.is_return ||
                        !!item.posa_is_offer ||
                        !!item.posa_is_replace
                      "
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined0
                      color="primary"
                      :label="frappe._('Rate')"
                      background-color="white"
                      hide-details
                      v-model.number="item.rate"
                      type="number"
                      :prefix="invoice_doc?.currency"
                      @change="handleDiscountItem(item, $event)"
                      id="rate"
                      :disabled="
                        !!item.posa_is_offer ||
                        !!item.posa_is_replace ||
                        !!item.posa_offer_applied ||
                        !pos_profile.custom_allow_user_to_edit_rate ||
                        !!invoice_doc?.is_return
                          ? true
                          : false
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Discount Percentage')"
                      background-color="white"
                      hide-details
                      v-model.number="item.discount_percentage"
                      type="number"
                      @change="handleDiscountItem(item, $event)"
                      id="discount_percentage"
                      :disabled="
                        !!item.posa_is_offer ||
                        !!item.posa_is_replace ||
                        item.posa_offer_applied ||
                        !pos_profile.custom_allow_user_to_edit_item_discount ||
                        !!invoice_doc?.is_return
                          ? true
                          : false
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Discount Amount')"
                      background-color="white"
                      hide-details
                      v-model.number="item.discount_amount"
                      type="number"
                      :prefix="invoice_doc?.currency"
                      @change="handleDiscountItem(item, $event)"
                      id="discount_amount"
                      :disabled="
                        !!item.posa_is_offer ||
                        !!item.posa_is_replace ||
                        !!item.posa_offer_applied ||
                        !pos_profile.custom_allow_user_to_edit_item_discount ||
                        !!invoice_doc?.is_return
                          ? true
                          : false
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Price list Rate')"
                      background-color="white"
                      hide-details
                      v-model="item.price_list_rate"
                      type="number"
                      disabled
                      :prefix="invoice_doc?.currency"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Available QTY')"
                      background-color="white"
                      hide-details
                      v-model="item.actual_qty"
                      type="number"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Group')"
                      background-color="white"
                      hide-details
                      v-model="item.item_group"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Stock QTY')"
                      background-color="white"
                      hide-details
                      v-model="item.stock_qty"
                      type="number"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Stock UOM')"
                      background-color="white"
                      hide-details
                      v-model="item.stock_uom"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col align="center" cols="4" v-if="item.posa_offer_applied">
                    <v-checkbox
                      dense
                      :label="frappe._('Offer Applied')"
                      v-model="item.posa_offer_applied"
                      readonly
                      hide-details
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" v-if="item.has_serial_no == 1 || item.serial_no">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Serial No QTY')"
                      background-color="white"
                      hide-details
                      v-model="item.serial_no_selected_count"
                      type="number"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="item.has_serial_no == 1 || item.serial_no">
                    <v-autocomplete
                      v-model="item.serial_no_selected"
                      :items="item.serial_no_data"
                      item-text="serial_no"
                      outlined
                      dense
                      chips
                      color="primary"
                      small-chips
                      :label="frappe._('Serial No')"
                      multiple
                      @change="set_serial_no(item)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4" v-if="item.has_batch_no == 1 || item.batch_no">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Batch No Available QTY')"
                      background-color="white"
                      hide-details
                      v-model="item.actual_batch_qty"
                      type="number"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="item.has_batch_no == 1 || item.batch_no">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      :label="frappe._('Batch No Expiry Date')"
                      background-color="white"
                      hide-details
                      v-model="item.batch_no_expiry_date"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" v-if="item.has_batch_no == 1 || item.batch_no">
                    <v-autocomplete
                      v-model="item.batch_no"
                      :items="item.batch_no_data"
                      item-text="batch_no"
                      outlined
                      dense
                      color="primary"
                      :label="frappe._('Batch No')"
                      @change="set_batch_qty(item, $event)"
                    >
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.batch_no"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="
                                `Available QTY  '${data.item.batch_qty}' - Expiry Date ${data.item.expiry_date}`
                              "
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="4"
                    v-if="pos_profile.custom_allow_create_sales_order && invoiceType == 'Order'"
                  >
                    <v-menu
                      ref="item_delivery_date"
                      v-model="item.item_delivery_date"
                      :close-on-content-click="false"
                      :return-value.sync="item.posa_delivery_date"
                      transition="scale-transition"
                      dense
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.posa_delivery_date"
                          :label="frappe._('Delivery Date')"
                          readonly
                          outlined
                          dense
                          clearable
                          color="primary"
                          hide-details
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.posa_delivery_date"
                        no-title
                        scrollable
                        color="primary"
                        :min="formattedDateNow"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="item.item_delivery_date = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            [
                              $refs.item_delivery_date.save(item.posa_delivery_date),
                              validate_due_date(item),
                            ]
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="8" v-if="pos_profile.custom_display_additional_notes">
                    <v-textarea
                      class="pa-0"
                      outlined
                      dense
                      clearable
                      color="primary"
                      auto-grow
                      rows="1"
                      :label="frappe._('Additional Notes')"
                      v-model="item.take_note"
                      :value="item.take_note"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </template>
      </div>
    </v-card>
    <v-card class="cards mb-0 mt-3 py-0 grey lighten-5">
      <v-row no-gutters>
        <v-col cols="7">
          <v-row no-gutters class="pa-1 pt-9 pr-1">
            <v-col cols="6" class="pa-1">
              <v-text-field
                :value="formtFloat(total_qty)"
                :label="frappe._('total Qty')"
                outlined
                dense
                readonly
                hide-details
                color="accent"
              ></v-text-field>
            </v-col>
            <v-col v-if="!pos_profile.custom_use_percentage_discount" cols="6" class="pa-1">
              <v-text-field
                v-model="discount_amount"
                :label="frappe._('Additional Discount')"
                ref="discount"
                outlined
                dense
                hide-details
                color="warning"
                type="number"
                :prefix="pos_profile.currency"
                :disabled="
                  !pos_profile.custom_allow_user_to_edit_additional_discount ||
                  discount_percentage_offer_name
                    ? true
                    : false
                "
              ></v-text-field>
            </v-col>
            <v-col v-if="pos_profile.custom_use_percentage_discount" cols="6" class="pa-1">
              <v-text-field
                v-model="additional_discount_percentage"
                :label="frappe._('Additional Discount %')"
                ref="percentage_discount"
                outlined
                dense
                color="warning"
                hide-details
                type="number"
                :disabled="
                  !pos_profile.custom_allow_user_to_edit_additional_discount ||
                  discount_percentage_offer_name
                    ? true
                    : false
                "
                @change="handleChangeDiscountAmount"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-1 mt-2">
              <v-text-field
                :value="formtCurrency(total_items_discount_amount)"
                :label="frappe._('Items Discounts')"
                outlined
                dense
                color="warning"
                readonly
                hide-details
                :prefix="pos_profile.currency"
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="pa-1 mt-2">
              <v-text-field
                :value="formtCurrency(subtotal)"
                :label="frappe._('total')"
                outlined
                dense
                readonly
                hide-details
                color="success"
                :prefix="pos_profile.currency"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-row no-gutters class="pa-1 pt-2 pl-0">
            <v-col cols="6" class="pa-1">
              <v-btn
                block
                class="pa-0"
                color="warning"
                dark
                @click="get_draft_invoices"
                >{{ __("Held") }}</v-btn
              >
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-btn
                block
                class="pa-0"
                :class="{ 'disable-events': !pos_profile.custom_allow_return }"
                color="secondary"
                dark
                @click="openReturns"
                >{{ __("Return") }}</v-btn
              >
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-btn
                block
                class="pa-0"
                color="error"
                dark
                @click="isCancelInvoice = true"
                >{{ __("Cancel") }}</v-btn
              >
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-btn block class="pa-0" color="accent" dark @click="newInvoice">{{
                __("Save/New")
              }}</v-btn>
            </v-col>
            <v-col class="pa-1">
              <v-btn block class="pa-0" color="success" @click="onShowPayment" dark>{{
                __("PAY")
              }}</v-btn>
            </v-col>
            <v-col
              v-if="pos_profile.custom_allow_print_draft_invoices"
              cols="6"
              class="pa-1"
            >
              <v-btn
                block
                class="pa-0"
                color="primary"
                @click="print_draft_invoice"
                dark
                >{{ __("Print Draft") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div> -->
  <div class="w-full space-y-4">
    <div class="flex w-full items-center">
      <div v-if="pos_profile.custom_allow_combine_item" class="mr-4 w-32">
        <button
          type="button"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] text-white rounded-md w-full justify-center font-bold"
          @click="handleAddCombine"
          :disabled="isAdded"
          :class="isAdded ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-700'"
        >
          + Add
        </button>
      </div>
      <div
        class="w-full"
        :class="
          pos_profile.custom_allow_create_sales_order &&
          'grid grid-cols-6 gap-4'
        "
      >
        <div
          class="col-span-4"
          v-if="pos_profile.custom_allow_create_sales_order"
        >
          <CustomerComponent></CustomerComponent>
        </div>
        <div v-if="!pos_profile.custom_allow_create_sales_order">
          <CustomerComponent></CustomerComponent>
        </div>
        <div
          class="col-span-2"
          v-if="pos_profile.custom_allow_create_sales_order"
        >
          <multiselect
            v-model="invoiceType"
            :options="invoiceTypes"
            :searchable="false"
            placeholder=""
            :allow-empty="false"
            deselect-label="Can't remove this value"
            :disabled="invoiceType == 'Return'"
          />
        </div>
      </div>
    </div>

    <div
      class="w-full space-y-4 overflow-auto h-[calc(100vh_-_355px)] sm:h-[calc(100vh_-_282px)] lg:h-[calc(100vh_-_320px)]"
    >
      <div v-if="pos_profile.custom_allow_change_posting_date">
        <label
          for="posting_date"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >Posting Date</label
        >
        <div>
          <vue-tailwind-datepicker
            id="posting_date"
            v-model="posting_date"
            :formatter="{
              date: 'YYYY-MM-DD',
              month: 'MMM',
            }"
            as-single
          />
        </div>
      </div>
      <TableComponent
        :columns="columns"
        :items="items"
        :sort="false"
        :expanded="(item) => (expanded = [item])"
        scrollX="60rem"
      >
        <template #bodyCell="{ column, record, index }">
          <template v-if="!record.is_combine && !record.custom_parent_item">
            <template v-if="column.key === 'item_name'"
              >{{ record.item_name }}
            </template>
            <template v-if="column.key === 'qty'">
              <div class="col-span-2 flex space-x-2 items-center">
                <button
                  class="p-[2px] rounded-md bg-gray-100/70 text-gray-600 dark:bg-slate-800 dark:text-white"
                  :class="
                    record.qty == 1
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                  "
                  :disabled="record.qty == 1"
                  @click="decrementqtyByCartItem(record)"
                >
                  <svg
                    class="h-5 w-5"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                  </svg>
                </button>
                <input
                  :id="`qty` + index"
                  type="number"
                  v-model="record.qty"
                  @input="handleChangeQTY(record, $event.target.value)"
                  class="w-full px-2 py-1 text-center dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                />
                <button
                  class="p-[2px] bg-gray-100/70 text-gray-600 dark:bg-slate-800 dark:text-white"
                  @click="incrementqtyByCartItem(record)"
                >
                  <svg
                    class="h-5 w-5"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1="12" y1="5" x2="12" y2="19" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                  </svg>
                </button>
              </div>
            </template>
            <template v-if="column.key === 'discount'">
              <!-- <InputComponent
                :id="`discount_percentage` + index"
                type="number"
                v-model="record.discount_percentage"
                :change="
                  (event) =>
                    handleDiscountItem(
                      event.target.value,
                      record,
                      event.target.id,
                      index
                    )
                "
                :disabled="
                  !!record.posa_is_offer ||
                  !!record.posa_is_replace ||
                  record.posa_offer_applied ||
                  !pos_profile.custom_allow_user_to_edit_item_discount ||
                  !!invoice_doc?.is_return
                    ? true
                    : false
                "
              /> -->
              <InputCurrencyComponent
                v-if="
                  !!record.posa_is_offer ||
                  !!record.posa_is_replace ||
                  record.posa_offer_applied ||
                  !pos_profile.custom_allow_user_to_edit_item_discount ||
                  !!invoice_doc?.is_return
                    ? true
                    : false
                "
                :id="`discount_percentage` + index"
                v-model="record.discount_percentage"
                disabled
              />
              <div v-else>
                <div class="relative">
                  <multiselect
                    :id="`discount_percentage` + index"
                    v-model="record.discount_percentage"
                    :options="getDiscountRate.map((item) => item.value)"
                    placeholder=""
                    :searchable="false"
                    @select="
                      (value, event) =>
                        handleDiscountItem(value, record, event, index)
                    "
                  />
                </div>
              </div>
            </template>
            <template v-if="column.key === 'uom'">{{ record.uom }} </template>
            <template v-if="column.key === 'rate'"
              ><span>{{ getCurrencySymbol }}</span
              > {{ formtCurrency(record.rate) }}
            </template>
            <template v-if="column.key === 'amount'"
              ><span>{{ getCurrencySymbol }}</span
              > {{ formtCurrency(record.rate * record.qty) }}</template
            >
            <template v-if="column.key === 'custom_is_print'">
              <input
                type="checkbox"
                v-model="record.custom_is_print"
                class="h-4 w-4 cursor-pointer accent-secondary"
              />
            </template>
            <template v-if="column.key === 'action'">
              <button
                type="button"
                class="p-1 text-red-400"
                @click.stop="handleRemoveItem(record)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button> </template
          ></template>
          <template v-else-if="record.is_combine">
            <template v-if="column.key === 'item_name'"
              ><InputComponent
                :id="`item_name` + index"
                v-model="record.item_name"
              />
            </template>
            <template v-if="column.key === 'rate'">
              <InputCurrencyComponent
                :id="`rate` + index"
                :prefix="getCurrencySymbol"
                v-model="record.rate"
                :change="
                  (event) =>
                    handleDiscountItem(
                      event.target.value,
                      record,
                      event.target.id,
                      index
                    )
                "
              />
            </template>
            <template v-if="column.key == 'action'">
              <button
                type="button"
                class="p-1 text-red-400"
                @click.stop="handleRemoveItem(record)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </template>
          </template>
        </template>
        <template #expandedRowRender="{ record, index }">
          <template v-if="!record.is_combine">
            <div class="grid grid-cols-3 gap-4 w-full p-2">
              <!-- <InputComponent
              id="item_code"
              label="Item Code"
              v-model="record.item_code"
              disabled
            /> -->

              <div>
                <label
                  :for="`qty` + index"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >QTY</label
                >
                <div>
                  <input
                    :id="`qty` + index"
                    type="number"
                    v-model="record.qty"
                    :disabled="
                      !!record.posa_is_offer || !!record.posa_is_replace
                    "
                    class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                  />
                </div>
              </div>
              <!-- <InputComponent
              :id="`uom` + index"
              label="UOM"
              v-model="record.uom"
              :disabled="
                !!invoice_doc?.is_return ||
                !!record.posa_is_offer ||
                !!record.posa_is_replace
              "
            /> -->
              <div>
                <label
                  class="text-4x flex space-x-2 items-center dark:text-gray-200"
                  >UOM
                </label>
                <multiselect
                  v-model="record.uom"
                  :options="
                    record.active ? record.item_uoms.map((uom) => uom.uom) : []
                  "
                  placeholder=""
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                  @select="(value) => handleChangeUOM(record, value)"
                  :disabled="
                    !!invoice_doc?.is_return ||
                    !!record.posa_is_offer ||
                    !!record.posa_is_replace
                  "
                />
              </div>
              <InputCurrencyComponent
                :id="`rate` + index"
                label="Price"
                :prefix="getCurrencySymbol"
                v-model="record.rate"
                :change="
                  (event) =>
                    handleDiscountItem(
                      event.target.value,
                      record,
                      event.target.id,
                      index
                    )
                "
                :disabled="
                  !!record.posa_is_offer ||
                  !!record.posa_is_replace ||
                  !!record.posa_offer_applied ||
                  !pos_profile.custom_allow_user_to_edit_rate ||
                  !!invoice_doc?.is_return
                    ? true
                    : false
                "
              />

              <InputCurrencyComponent
                v-if="
                  !!record.posa_is_offer ||
                  !!record.posa_is_replace ||
                  record.posa_offer_applied ||
                  !pos_profile.custom_allow_user_to_edit_item_discount ||
                  !!invoice_doc?.is_return
                    ? true
                    : false
                "
                :id="`discount_percentage` + index"
                label="Discount Percentage"
                v-model="record.discount_percentage"
                :disabled="
                  !!record.posa_is_offer ||
                  !!record.posa_is_replace ||
                  record.posa_offer_applied ||
                  !pos_profile.custom_allow_user_to_edit_item_discount ||
                  !!invoice_doc?.is_return
                    ? true
                    : false
                "
              />
              <div v-else>
                <label
                  class="text-4x flex space-x-2 items-center dark:text-gray-200"
                  >Discount Percentage
                </label>
                <div class="relative">
                  <multiselect
                    :id="`discount_percentage` + index"
                    v-model="record.discount_percentage"
                    :options="getDiscountRate.map((item) => item.value)"
                    placeholder=""
                    :searchable="false"
                    @select="
                      (value, event) =>
                        handleDiscountItem(value, record, event, index)
                    "
                  />
                </div>
              </div>
              <InputComponent
                :id="`discount_amount` + index"
                label="Discount Amount"
                v-model="record.discount_amount"
                :change="
                  (event) =>
                    handleDiscountItem(
                      event.target.value,
                      record,
                      event.target.id,
                      index
                    )
                "
                :disabled="
                  !!record.posa_is_offer ||
                  !!record.posa_is_replace ||
                  !!record.posa_offer_applied ||
                  !pos_profile.custom_allow_user_to_edit_item_discount ||
                  !!invoice_doc?.is_return
                    ? true
                    : false
                "
              />
              <div
                v-if="
                  pos_profile.custom_allow_create_sales_order &&
                  invoiceType == 'Order'
                "
              >
                <label
                  :for="`posa_delivery_date` + index"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >Delivery Date</label
                >
                <div>
                  <vue-tailwind-datepicker
                    :id="`posa_delivery_date` + index"
                    v-model="record.posa_delivery_date"
                    :formatter="{
                      date: 'YYYY-MM-DD',
                      month: 'MMM',
                    }"
                    as-single
                  />
                </div>
              </div>
              <div
                class="col-span-2"
                v-if="pos_profile.custom_display_additional_notes"
              >
                <InputComponent
                  :id="`take_note` + index"
                  label="Additional Notes"
                  v-model="record.take_note"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-for="record in record.items"
              class="grid grid-cols-8 gap-4 w-full pr-4 py-2 pl-16 items-center"
            >
              <div class="col-span-3 text-gray-600 dark:text-white">
                {{ record.item_name }}
              </div>
              <div class="col-span-2">
                <div class="flex space-x-2 items-center">
                  <button
                    class="p-[2px] rounded-md bg-gray-100/70 text-gray-600 dark:bg-slate-800 dark:text-white"
                    :class="
                      record.qty == 1
                        ? 'cursor-not-allowed opacity-50'
                        : 'cursor-pointer'
                    "
                    :disabled="record.qty == 1"
                    @click="decrementqtyByCartItem(record)"
                  >
                    <svg
                      class="h-5 w-5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                  </button>
                  <input
                    :id="`qty` + index"
                    type="number"
                    v-model="record.qty"
                    @input="handleChangeQTY(record, $event.target.value)"
                    class="w-full px-2 py-1 text-center dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                  />
                  <button
                    class="p-[2px] bg-gray-100/70 text-gray-600 dark:bg-slate-800 dark:text-white"
                    @click="incrementqtyByCartItem(record)"
                  >
                    <svg
                      class="h-5 w-5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <line x1="12" y1="5" x2="12" y2="19" />
                      <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="col-span-3 text-right">
                <button
                  type="button"
                  class="p-1 text-red-400"
                  @click.stop="handleRemoveItemChild(record)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </template>
        </template>
      </TableComponent>
    </div>
    <div
      class="grid grid-cols-1 sm:grid-cols-3 gap-y-2 sm:gap-4 items-center rounded-md"
    >
      <div
        class="col-span-2 grid grid-cols-5 gap-2 sm:gap-4 items-center dark:text-white dark:border-slate-600"
      >
        <div class="col-span-2 space-y-2 sm:space-y-4">
          <div
            class="flex justify-between items-center pb-4 border-dotted border-b dark:border-slate-600"
          >
            <p>Total QTY</p>
            <p class="font-bold">{{ total_qty }}</p>
          </div>
          <div class="flex justify-between items-center">
            <p class="ellipsis-line line-clamp-1">Item Discount</p>
            <p class="font-bold">
              <span>{{ getCurrencySymbol }}</span
                > {{ $n(total_items_discount_amount, "decimal", pos_profile.currency === 'USD' ? 'en' : 'kh') }}
            </p>
          </div>
        </div>
        <div
          class="col-span-3 space-y-2 sm:space-y-4 border-dotted border-l sm:border-x pl-2 sm:px-4 dark:border-slate-600"
        >
          <div
            class="flex justify-between items-center pb-2.5 border-dotted border-b dark:border-slate-600"
          >
            <p class="mr-2">Discount</p>
            <p class="font-medium">
              <InputCurrencyComponent
                v-if="!pos_profile.custom_use_percentage_discount"
                id="discount_amount"
                v-model="discount_amount"
                :prefix="getCurrencySymbol"
                ref="discount"
                :disabled="
                  !pos_profile.custom_allow_user_to_edit_additional_discount ||
                  discount_percentage_offer_name
                    ? true
                    : false
                "
              />
              <InputCurrencyComponent
                v-if="pos_profile.custom_use_percentage_discount"
                id="percentage_discount"
                v-model="additional_discount_percentage"
                @change="handleChangeDiscountAmount"
                suffix="%"
                ref="percentage_discount"
                :disabled="
                  !pos_profile.custom_allow_user_to_edit_additional_discount ||
                  discount_percentage_offer_name
                    ? true
                    : false
                "
              />
            </p>
          </div>
          <div class="flex justify-between items-center">
            <h1>TOTAL</h1>
            <p class="text-2xl font-bold">
              <span>{{ getCurrencySymbol }}</span
                > {{$n(subtotal, "decimal", pos_profile.currency === 'USD' ? 'en' : 'kh') }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-span-1 space-y-2 sm:space-y-4">
        <div class="grid grid-cols-2 gap-2 sm:gap-4">
          <button
            type="button"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-gray-600 text-white rounded-md w-full justify-center font-bold"
            @click="isCancelInvoice = true"
          >
            Cancel
          </button>
          <button
            type="button"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-green-700 text-white rounded-md w-full justify-center font-bold"
            @click="getDraftInvoices"
          >
            Hold
          </button>
          <button
            type="button"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white rounded-md w-full justify-center font-bold"
            :disabled="!pos_profile.custom_allow_return"
            @click="openReturns"
          >
            Return
          </button>
          <button
            type="button"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-blue-700 text-white rounded-md w-full justify-center font-bold"
            @click="handleNewInvoice"
          >
            Save/New
          </button>
        </div>
        <div class="flex">
          <button
            type="button"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-red-400 text-white rounded-md w-full justify-center font-bold sm:hidden mr-2"
            @click="onBackOrder"
          >
            Back
          </button>
          <button
            type="button"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-primary text-white rounded-md w-full justify-center font-bold"
            @click="onShowPayment"
          >
            Pay
          </button>
        </div>
      </div>
    </div>
  </div>
  <ModalComponent
    title="Confirm"
    :isModalOpen="isCancelInvoice"
    :onToggle="() => (this.isCancelInvoice = false)"
    :width="378"
    :freeSpaceClose="true"
  >
    <div class="py-2">
      <h1 class="dark:text-gray-100">Do you want to cancel invoice?</h1>
    </div>
    <div class="flex justify-end pt-2 lg:pt-4 gap-4">
      <button
        @click="isCancelInvoice = false"
        class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
      >
        Cancel
      </button>
      <button
        type="button"
        @click="handleCancelInvoice"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        Yes
      </button>
    </div>
  </ModalComponent>
</template>

<script>
const columns = [
  {
    key: "item_name",
    label: "Name",
    width: 250,
  },
  {
    key: "qty",
    label: "QTY",
    alignHeader: "center",
    width: 160,
  },
  {
    key: "discount",
    label: "Discount(%)",
    alignHeader: "center",
    width: 150,
  },
  {
    key: "uom",
    label: "UOM",
    alignHeader: "center",
    align: "center",
    width: 150,
  },
  {
    key: "rate",
    label: "Price",
    alignHeader: "center",
    align: "center",
    width: 150,
  },
  {
    key: "amount",
    label: "Amount",
    alignHeader: "center",
    align: "center",
    width: 150,
  },
  {
    key: "custom_is_print",
    label: "Is Print",
    alignHeader: "center",
    align: "center",
    width: 90,
  },
  {
    key: "action",
    label: "",
    align: "center",
    width: 50,
  },
];
import CustomerComponent from "./CustomerComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import imageEmpty from "@/assets/image/empty.jpg";
import { mapActions, mapGetters } from "vuex";
import TableComponent from "@/components/table/TableComponent.vue";
import {
  renderItems,
  formattedDateNow,
  formtCurrency,
} from "@/utils/globleFunction";
import InputComponent from "@/components/input/InputComponent.vue";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { dispatchNotification } from "@/components/notification";
import _ from "lodash";
import { Switch } from "@headlessui/vue";
import { type } from "@/services/socketio.type";
import { socket } from "@/services/socketio.service.js";

export default {
  components: {
    SpinComponent,
    CustomerComponent,
    TableComponent,
    InputComponent,
    InputCurrencyComponent,
    ModalComponent,
    Switch,
  },
  data() {
    return {
      formtCurrency,
      columns,
      renderItems,
      text_input: "",
      autocomplete_text: "",
      charger_value: "",
      dpos_opening_entry: "",
      stock_settings: "",
      invoice_doc: null,
      return_doc: "",
      customer: "",
      customer_info: "",
      discount_amount: 0,
      additional_discount_percentage: 0,
      total_tax: 0,
      items: [],
      posOffers: [],
      posa_offers: [],
      posa_coupons: [],
      discount_percentage_offer_name: null,
      invoiceTypes: ["Invoice", "Order"],
      invoiceType: "Invoice",
      itemsPerPage: 1000,
      expanded: [],
      singleExpand: true,
      isCancelInvoice: false,
      float_precision: 2,
      currency_precision: 2,
      new_line: false,
      delivery_charges: [],
      delivery_charges_rate: 0,
      selcted_delivery_charges: {},
      invoice_posting_date: false,
      posting_date: formattedDateNow,
    };
  },
  created() {
    this.emitter.on("addItemToCart", (item) => {
      this.addItemToCart(item);
    });
    this.emitter.on("updateCustomer", (customer) => {
      this.customer = customer;
    });
    this.emitter.on("newInvoice", () => {
      this.invoice_doc = null;
      this.handleCancelInvoice();
    });
    this.emitter.on("loadInvoice", (data) => {
      this.handleNewInvoice(data);
      // this.emitter.emit("set_pos_coupons", data.posa_coupons);
    });
    // this.emitter.on("set_offers", (data) => {
    //   this.posOffers = data;
    // });
    // this.emitter.on("update_invoice_offers", (data) => {
    //   this.updateInvoiceOffers(data);
    // });
    // this.emitter.on("update_invoice_coupons", (data) => {
    //   this.posa_coupons = data;
    //   this.handelOffers();
    // });
    // this.emitter.on("set_all_items", (data) => {
    //   this.items.forEach((item) => {
    //     this.getItem(item);
    //   });
    // });
    this.emitter.on("loadReturnInvoice", (data) => {
      this.handleNewInvoice(data.invoice_doc);
      this.discount_amount = -data.return_doc.discount_amount;
      this.additional_discount_percentage =
        -data.return_doc.additional_discount_percentage;
      this.return_doc = data.return_doc;
    });
    this.emitter.on("setNewLine", (data) => {
      this.new_line = data;
    });
    // document.addEventListener("keydown", this.shortOpenPayment.bind(this));
    // document.addEventListener("keydown", this.shortDeleteFirstItem.bind(this));
    // document.addEventListener("keydown", this.shortOpenFirstItem.bind(this));
    // document.addEventListener("keydown", this.shortSelectDiscount.bind(this));
  },
  computed: {
    ...mapGetters("auth", {
      getDiscountRate: "getDiscountRate",
      getPOSProfile: "getPOSProfile",
      getCurrencySymbol: "getCurrencySymbol",
    }),
    ...mapGetters("retail", {
      userProfile: "userProfile",
      allItems: "allItems",
    }),
    pos_profile() {
      return this.userProfile.pos_profile;
    },
    isAdded() {
      return this.items.find((item) => item.is_combine);
    },
    total_qty() {
      this.closePayments();
      let qty = 0;
      this.items.forEach((item) => {
        qty += item.qty;
      });
      return Number(qty);
    },
    total() {
      let sum = 0;
      this.items.forEach((item) => {
        sum += item.qty * item.rate;
      });
      return Number(sum).toFixed(this.currency_precision);
    },
    subtotal() {
      this.closePayments();
      let sum = 0;
      this.items.forEach((item) => {
        sum += item.qty * item.rate;
      });
      sum -= Number(this.discount_amount);
      sum += Number(this.delivery_charges_rate);
      return Number(sum);
    },
    total_items_discount_amount() {
      let sum = 0;
      this.items.forEach((item) => {
        sum += item.qty * item.discount_amount;
      });
      return Number(sum);
    },
  },
  mounted() {
    this.dpos_opening_entry = this.userProfile.dpos_opening_entry;
  },
  watch: {
    total_qty(newValue) {
      this.emitter.emit("setQty", newValue);
    },
    customer() {
      this.closePayments();
      this.emitter.emit("setSustomer", this.customer);
      this.getCustomerDetails();
      this.setDeliveryCharges();
    },
    customer_info() {
      this.emitter.emit("set_customer_info_to_edit", this.customer_info);
    },
    expanded(data_value) {
      if (data_value.active) {
        this.getItems(data_value);
        if (data_value.length > 0) {
          this.getItem(data_value[0]);
        }
      }
    },
    discount_percentage_offer_name() {
      this.emitter.emit("update_discount_percentage_offer_name", {
        value: this.discount_percentage_offer_name,
      });
    },
    items: {
      deep: true,
      handler(items) {
        this.handelOffers();
        this.$forceUpdate();
        this.sendItems();
      },
    },
    invoiceType() {
      this.emitter.emit("update_invoice_type", this.invoiceType);
    },
    discount_amount() {
      if (!this.discount_amount || this.discount_amount == 0) {
        this.additional_discount_percentage = 0;
      } else if (this.pos_profile.custom_use_percentage_discount) {
        this.additional_discount_percentage =
          (this.discount_amount / this.total) * 100;
      } else {
        this.additional_discount_percentage = 0;
      }
      this.sendItems();
    },
  },
  methods: {
    onBackOrder(){
      this.emitter.emit("onShowCart", false);
    },
    sendItems() {
      socket.emit("send", {
        pos_profile: this.getPOSProfile,
        type: type.refCFD,
        data: this.items,
        total: this.subtotal,
        discount_amount: this.discount_amount,
      });
    },
    handleAddCombine() {
      this.items.unshift({
        item_code: "combine_item_code",
        item_name: "Combine Name",
        items: [],
        qty: 1,
        stock_qty: 0,
        price_list_rate: 0,
        rate: 0,
        amount: 0,
        discount_percentage: 0,
        discount_amount: 0,
        is_combine: true,
        active: true,
      });
      this.isCombine = true;
    },
    ...mapActions("retail", {
      setCustomer: "setCustomer",
    }),
    getItems(items) {
      dataService
        .post("get_items_details", {
          items_data: items,
        })
        .then((res) => {
          let data = res.data.message;
          if (data) {
            items.forEach((item) => {
              const updated_item = data.find(
                (element) => element.item_code == item.item_code
              );
              item.actual_qty = updated_item.actual_qty;
              item.serial_no_data = updated_item.serial_no_data;
              item.batch_no_data = updated_item.batch_no_data;
              item.item_uoms = updated_item.item_uoms;
            });
          }
        });
    },
    handleRemoveItem(item) {
      const index = this.items.findIndex(
        (el) => el.posa_row_id == item.posa_row_id
      );
      if (index >= 0) {
        this.items.splice(index, 1);
      }
      const idx = this.expanded.findIndex(
        (el) => el.posa_row_id == item.posa_row_id
      );
      if (idx >= 0) {
        this.expanded.splice(idx, 1);
      }
    },
    handleRemoveItemChild(item) {
      const itemCombine = this.items.find(
        (i) => i.item_code == "combine_item_code"
      );
      itemCombine.active = false;
      if (itemCombine) {
        const index = itemCombine.items.findIndex(
          (el) => el.posa_row_id == item.posa_row_id
        );
        if (index >= 0) {
          itemCombine.items.splice(index, 1);
          setTimeout(() => {
            itemCombine.active = true;
          }, 100);
        }
      }
    },
    incrementqtyByCartItem(item) {
      item.qty++;
      this.handleChangeQTY(item, item.qty);
      this.$forceUpdate();
    },
    decrementqtyByCartItem(item) {
      item.qty--;
      this.handleChangeQTY(item, item.qty);
      this.$forceUpdate();
    },
    async addItemToCart(item) {
      const itemCombine = this.items.find(
        (i) => i.item_code == "combine_item_code"
      );
      if (itemCombine && itemCombine.active) {
        itemCombine.active = false;
        if (!itemCombine.item_name) {
          dispatchNotification({
            content: "Please enter combine name!",
            type: "warning",
          });
          return;
        }
        if (!item.uom) {
          item.uom = item.stock_uom;
        }
        let index = -1;
        if (!this.new_item) {
          index = itemCombine.items.findIndex(
            (el) =>
              el.item_code === item.item_code &&
              el.uom === item.uom &&
              !el.posa_is_offer &&
              !el.posa_is_replace
          );
        }
        if (index === -1 || this.new_line) {
          const new_item = this.getNewItem(item);
          if (item.has_serial_no && item.to_set_serial_no) {
            new_item.serial_no_selected = [];
            new_item.serial_no_selected.push(item.to_set_serial_no);
            item.to_set_serial_no = null;
          }
          await this.getItem(new_item);
          itemCombine.items.unshift(new_item);
        } else {
          const cur_item = this.items.find(
            (i) => i.item_code == "combine_item_code"
          ).items[index];
          this.getItems([cur_item]);
          if (item.has_serial_no && item.to_set_serial_no) {
            if (cur_item.serial_no_selected.includes(item.to_set_serial_no)) {
              this.emitter.emit("show_mesage", {
                text: __(`This Serial Number {0} has already been added!`, [
                  item.to_set_serial_no,
                ]),
                color: "warning",
              });
              item.to_set_serial_no = null;
              return;
            }
            cur_item.serial_no_selected.push(item.to_set_serial_no);
            item.to_set_serial_no = null;
          }
          if (!cur_item.has_batch_no) {
            cur_item.qty += item.qty || 1;
            this.handleChangeQTY(cur_item, cur_item.qty);
          } else {
            if (
              cur_item.stock_qty < cur_item.actual_batch_qty ||
              !cur_item.batch_no
            ) {
              cur_item.qty += item.qty || 1;
              this.handleChangeQTY(cur_item, cur_item.qty);
            } else {
              const new_item = this.getNewItem(cur_item);
              new_item.batch_no = "";
              new_item.batch_no_expiry_date = "";
              new_item.actual_batch_qty = "";
              new_item.qty = item.qty || 1;
              itemCombine.items.unshift(new_item);
            }
          }
          this.set_serial_no(cur_item);
        }
        itemCombine.active = true;
        return;
      }
      if (!item.uom) {
        item.uom = item.stock_uom;
      }
      let index = -1;
      if (!this.new_item) {
        index = this.items.findIndex(
          (el) =>
            el.item_code === item.item_code &&
            el.uom === item.uom &&
            !el.posa_is_offer &&
            !el.posa_is_replace
        );
      }
      if (index === -1 || this.new_line) {
        const new_item = this.getNewItem(item);

        if (item.has_serial_no && item.to_set_serial_no) {
          new_item.serial_no_selected = [];
          new_item.serial_no_selected.push(item.to_set_serial_no);
          item.to_set_serial_no = null;
        }
        await this.getItem(new_item);
        this.items.unshift(new_item);
      } else {
        const cur_item = this.items[index];
        this.getItems([cur_item]);
        if (item.has_serial_no && item.to_set_serial_no) {
          if (cur_item.serial_no_selected.includes(item.to_set_serial_no)) {
            this.emitter.emit("show_mesage", {
              text: __(`This Serial Number {0} has already been added!`, [
                item.to_set_serial_no,
              ]),
              color: "warning",
            });
            item.to_set_serial_no = null;
            return;
          }
          cur_item.serial_no_selected.push(item.to_set_serial_no);
          item.to_set_serial_no = null;
        }
        if (!cur_item.has_batch_no) {
          cur_item.qty += item.qty || 1;
          this.handleChangeQTY(cur_item, cur_item.qty);
        } else {
          if (
            cur_item.stock_qty < cur_item.actual_batch_qty ||
            !cur_item.batch_no
          ) {
            cur_item.qty += item.qty || 1;
            this.handleChangeQTY(cur_item, cur_item.qty);
          } else {
            const new_item = this.getNewItem(cur_item);
            new_item.batch_no = "";
            new_item.batch_no_expiry_date = "";
            new_item.actual_batch_qty = "";
            new_item.qty = item.qty || 1;
            this.items.unshift(new_item);
          }
        }
        this.set_serial_no(cur_item);
      }
    },

    getNewItem(item) {
      const new_item = { ...item };
      if (!item.qty) {
        item.qty = 1;
      }
      if (!item.posa_is_offer) {
        item.posa_is_offer = 0;
      }
      if (!item.posa_is_replace) {
        item.posa_is_replace = "";
      }
      new_item.stock_qty = item.qty;
      new_item.discount_amount = 0;
      new_item.discount_percentage = 0;
      new_item.discount_amount_per_item = 0;
      new_item.price_list_rate = item.rate;
      new_item.qty = item.qty;
      new_item.uom = item.uom ? item.uom : item.stock_uom;
      new_item.actual_batch_qty = "";
      new_item.conversion_factor = 1;
      new_item.posa_offers = JSON.stringify([]);
      new_item.posa_offer_applied = 0;
      new_item.posa_is_offer = item.posa_is_offer;
      new_item.posa_is_replace = item.posa_is_replace || null;
      new_item.is_free_item = 0;
      new_item.take_note = "";
      new_item.posa_delivery_date = "";
      new_item.posa_row_id = this.makeid(20);
      if (
        (!this.pos_profile.custom_auto_set_batch && new_item.has_batch_no) ||
        new_item.has_serial_no
      ) {
        this.expanded.push(new_item);
      }
      return new_item;
    },

    handleCancelInvoice() {
      const doc = this.getInvoiceDoc();
      this.invoiceType = "Invoice";
      this.invoiceTypes = ["Invoice", "Order"];
      this.posting_date = formattedDateNow;
      if (doc.name && this.pos_profile.custom_auto_delete_draft_invoice) {
        dataService
          .post("delete_invoice", { invoice: doc.name })
          .then((res) => {
            let data = res.data.message;
            if (data) {
              dispatchNotification({
                content: `${data}`,
                type: "success",
              });
            }
          });
      }
      this.items = [];
      this.posa_offers = [];
      // this.emitter.emit("set_pos_coupons", []);
      this.posa_coupons = [];
      this.customer = this.customer;
      this.invoice_doc = null;
      this.return_doc = "";
      this.discount_amount = 0;
      this.additional_discount_percentage = 0;
      this.delivery_charges_rate = 0;
      this.selcted_delivery_charges = {};
      this.emitter.emit("setCustomerReadonly", false);
      this.isCancelInvoice = false;
    },

    async handleNewInvoice(data = {}) {
      let old_invoice = null;
      this.emitter.emit("setCustomerReadonly", false);
      this.expanded = [];
      this.posa_offers = [];
      // this.emitter.emit("set_pos_coupons", []);
      this.posa_coupons = [];
      this.return_doc = "";
      const doc = this.getInvoiceDoc();
      if (doc.name) {
        old_invoice = await this.updateInvoice(doc);
      } else {
        if (doc.items.length) {
          old_invoice = await this.updateInvoice(doc);
        }
      }
      if (!data.name && !data.is_return) {
        this.items = [];
        this.customer = this.customer;
        this.invoice_doc = null;
        this.discount_amount = 0;
        this.additional_discount_percentage = 0;
        this.invoiceType = "Invoice";
        this.invoiceTypes = ["Invoice", "Order"];
      } else {
        if (data.is_return) {
          this.emitter.emit("setCustomerReadonly", true);
          this.invoiceType = "Return";
          this.invoiceTypes = ["Return"];
        }
        this.invoice_doc = data;
        this.items = data.items;
        this.getItems(this.items);
        this.posa_offers = data.posa_offers || [];
        this.items.forEach((item) => {
          if (!item.posa_row_id) {
            item.posa_row_id = this.makeid(20);
          }
          if (item.batch_no) {
            this.set_batch_qty(item, item.batch_no);
          }
        });
        this.customer = data.customer;
        this.posting_date = data.posting_date || formattedDateNow;
        this.discount_amount = data.discount_amount;
        this.additional_discount_percentage =
          data.additional_discount_percentage;
        let result = [];
        let comItems = {};
        this.items.forEach((item) => {
          if (item.serial_no) {
            item.serial_no_selected = [];
            const serial_list = item.serial_no.split("\n");
            serial_list.forEach((element) => {
              if (element.length) {
                item.serial_no_selected.push(element);
              }
            });
            item.serial_no_selected_count = item.serial_no_selected.length;
          }
          if (item.custom_parent_item) {
            if (comItems[item.custom_parent_item]) {
              comItems[item.custom_parent_item].items.push(item);
            } else {
              comItems[item.custom_parent_item] = {
                item_code: item.custom_parent_item,
                item_name: item.take_note,
                items: [item],
                stock_qty: 0,
                price_list_rate: 0,
                discount_percentage: 0,
                discount_amount: 0,
                is_combine: true,
                active: false,
              };
            }
          } else {
            result.push(item);
          }
        });

        for (let key in comItems) {
          const comItem = comItems[key];
          const items = comItem.items;
          let totalRate = 0;
          let totalAmount = 0;
          items.forEach((item) => {
            totalRate += item.rate;
            totalAmount += item.amount;
          });
          comItem.rate = totalRate;
          comItem.amount = totalAmount;
          comItem.qty = totalAmount >= 0 ? 1 : -1;
          result.push(comItem);
        }
        this.items = result;
      }
      return old_invoice;
    },

    getInvoiceDoc() {
      let doc = {};
      if (this.invoice_doc?.name) {
        doc = { ...this.invoice_doc };
      }
      doc.doctype = "Sales Invoice";
      doc.is_pos = 1;
      doc.ignore_pricing_rule = 1;
      doc.company = doc.company || this.pos_profile.company;
      doc.pos_profile = doc.pos_profile || this.pos_profile.name;
      doc.campaign = doc.campaign || this.pos_profile.campaign;
      doc.currency = doc.currency || this.pos_profile.currency;
      doc.code_branch = doc.code_branch || this.pos_profile.code_branch;
      doc.set_warehouse = doc.set_warehouse || this.pos_profile.warehouse;
      doc.naming_series = doc.naming_series || this.pos_profile.naming_series;
      doc.customer = this.customer;
      doc.items = this.getInvoiceItems();
      doc.total = this.subtotal;
      doc.discount_amount = Number(this.discount_amount);
      doc.additional_discount_percentage = Number(
        this.additional_discount_percentage
      );
      doc.custom_pos_entry = this.dpos_opening_entry.name;
      doc.payments = this.get_payments();
      doc.taxes = [];
      doc.is_return = this.invoice_doc?.is_return;
      doc.return_against = this.invoice_doc?.return_against;
      doc.posa_offers = this.posa_offers;
      doc.posa_coupons = this.posa_coupons;
      doc.posa_delivery_charges = this.selcted_delivery_charges.name;
      doc.posa_delivery_charges_rate = this.delivery_charges_rate || 0;
      doc.posting_date = this.posting_date;
      return doc;
    },

    getInvoiceItems() {
      const items_list = [];
      this.items.forEach((item) => {
        if (item.is_combine) {
          item.items.forEach((itemCombine) => {
            const newItem = {
              item_code: itemCombine.item_code,
              posa_row_id: itemCombine.posa_row_id,
              custom_is_print: itemCombine.custom_is_print,
              custom_parent_item: itemCombine.custom_parent_item,
              posa_offers: itemCombine.posa_offers,
              posa_offer_applied: itemCombine.posa_offer_applied,
              posa_is_offer: itemCombine.posa_is_offer,
              posa_is_replace: itemCombine.posa_is_replace,
              is_free_item: itemCombine.is_free_item,
              qty: itemCombine.qty,
              rate: item.rate / item.items.length / itemCombine.qty,
              uom: itemCombine.uom,
              amount: item.rate / item.items.length,
              conversion_factor: itemCombine.conversion_factor,
              serial_no: itemCombine.serial_no,
              discount_percentage: itemCombine.discount_percentage,
              discount_amount: itemCombine.discount_amount,
              batch_no: itemCombine.batch_no,
              take_note: item.item_name,
              posa_delivery_date: itemCombine.posa_delivery_date,
              price_list_rate: itemCombine.price_list_rate,
              custom_parent_item: item.item_code,
            };
            items_list.push(newItem);
          });
        } else {
          const newItem = {
            item_code: item.item_code,
            posa_row_id: item.posa_row_id,
            custom_is_print: item.custom_is_print,
            custom_parent_item: item.custom_parent_item,
            posa_offers: item.posa_offers,
            posa_offer_applied: item.posa_offer_applied,
            posa_is_offer: item.posa_is_offer,
            posa_is_replace: item.posa_is_replace,
            is_free_item: item.is_free_item,
            qty: item.qty,
            rate: item.rate,
            uom: item.uom,
            amount: item.qty * item.rate,
            conversion_factor: item.conversion_factor,
            serial_no: item.serial_no,
            discount_percentage: item.discount_percentage,
            discount_amount: item.discount_amount,
            batch_no: item.batch_no,
            take_note: item.take_note,
            posa_delivery_date: item.posa_delivery_date,
            price_list_rate: item.price_list_rate,
          };
          items_list.push(newItem);
        }
      });
      return items_list;
    },
    getConvertItems() {
      const newItems = [];
      this.items.forEach((item) => {
        if (item.is_combine) {
          item.items.forEach((itemCombine) => {
            const newItem = {
              ...itemCombine,
              rate: item.rate / item.items.length / itemCombine.qty,
              amount: item.rate / item.items.length,
              take_note: item.item_name,
              custom_parent_item: item.item_code,
            };
            newItems.push(newItem);
          });
        } else {
          newItems.push(item);
        }
      });

      return newItems;
    },

    get_payments() {
      const payments = [];
      this.pos_profile.payments.forEach((payment) => {
        payments.push({
          amount: 0,
          mode_of_payment: payment.mode_of_payment,
          default: payment.default,
          account: "",
        });
      });
      return payments;
    },

    async updateInvoice(doc) {
      const vm = this;
      return await dataService
        .post("update_invoice", { data: doc })
        .then((res) => {
          vm.invoice_doc = res.data.message;
          return vm.invoice_doc;
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.closePayments();
        });
    },

    proces_invoice() {
      const doc = this.getInvoiceDoc();
      if (doc.name) {
        return this.updateInvoice(doc);
      } else {
        return this.updateInvoice(doc);
      }
    },

    onShowPayment() {
      if (!this.customer) {
        dispatchNotification({
          content: "There is no customer!",
          type: "warning",
        });
        return;
      }
      if (!this.items.length) {
        dispatchNotification({
          content: "There is no items!",
          type: "warning",
        });
        return;
      }
      if (!this.validate()) {
        return;
      }
      this.emitter.emit("onShowPayment", true);
      const invoice_doc = this.proces_invoice();
      this.emitter.emit("sendInvoiceDocPayment", invoice_doc);
    },

    validate() {
      let value = true;
      console.log(this.getConvertItems());
      this.getConvertItems().forEach((item) => {
        if (
          this.pos_profile.custom_max_discount_percentage_allowed &&
          !item.posa_offer_applied
        ) {
          if (item.discount_amount && Number(item.discount_amount) > 0) {
            // calc discount percentage
            const discount_percentage =
              (Number(item.discount_amount) * 100) /
              Number(item.price_list_rate);
            if (
              discount_percentage >
              this.pos_profile.custom_max_discount_percentage_allowed
            ) {
              dispatchNotification({
                content: `Discount percentage for item ${item.item_name} cannot be greater than ${this.pos_profile.custom_max_discount_percentage_allowed}%`,
                type: "warning",
              });
              value = false;
            }
          }
        }

        if (this.stock_settings.allow_negative_stock != 1) {
          if (
            this.invoiceType == "Invoice" &&
            ((item.is_stock_item && item.stock_qty && !item.actual_qty) ||
              (item.is_stock_item && item.stock_qty > item.actual_qty))
          ) {
            dispatchNotification({
              content: `The existing quantity 0 for item ${item.item_name} is not enough`,
              type: "warning",
            });
            value = false;
          }
        }

        if (
          item.max_discount > 0 &&
          item.discount_percentage > item.max_discount
        ) {
          dispatchNotification({
            content: `Maximum discount for Item ${item.item_name} is ${item.max_discount}%`,
            type: "warning",
          });
          value = false;
        }
        if (item.has_serial_no) {
          if (
            !this.invoice_doc?.is_return &&
            (!item.serial_no_selected ||
              item.stock_qty != item.serial_no_selected.length)
          ) {
            dispatchNotification({
              content: `Selected serial numbers of item ${item.item_name} is incorrect`,
              type: "warning",
            });
            value = false;
          }
        }
        // if (item.has_batch_no) {
        //   if (item.stock_qty > item.actual_batch_qty) {
        //     dispatchNotification({
        //       content: `The existing batch quantity of item ${item.item_name} is not enough`,
        //       type: "warning",
        //     });
        //     value = false;
        //   }
        // }
        if (this.pos_profile.custom_allow_user_to_edit_additional_discount) {
          const clac_percentage = (this.discount_amount / this.total) * 100;
          if (
            clac_percentage >
            this.pos_profile.custom_max_discount_percentage_allowed
          ) {
            dispatchNotification({
              content: `The discount should not be higher than ${this.pos_profile.custom_max_discount_percentage_allowed}%`,
              type: "warning",
            });
            value = false;
          }
        }
        if (this.invoice_doc?.is_return) {
          if (this.subtotal >= 0) {
            dispatchNotification({
              content: `Return Invoice total Not Correct`,
              type: "warning",
            });
            value = false;
            return value;
          }
          if (this.subtotal * -1 > this.return_doc.total) {
            dispatchNotification({
              content: `Return Invoice total should not be higher than ${this.return_doc.total}`,
              type: "warning",
            });
            value = false;
            return value;
          }
          this.getConvertItems().forEach((item) => {
            const return_item = this.return_doc.items.find(
              (element) => element.item_code == item.item_code
            );

            if (!return_item) {
              dispatchNotification({
                content: `The item ${item.item_name} cannot be returned because it is not in the invoice ${this.return_doc.name}`,
                type: "warning",
              });
              value = false;
              return value;
            } else if (item.qty * -1 > return_item.qty || item.qty >= 0) {
              dispatchNotification({
                content: `The QTY of the item ${item.item_name} cannot be greater than ${return_item.qty}`,
                type: "warning",
              });
              value = false;
              return value;
            }
          });
        }
      });
      return value;
    },

    getDraftInvoices() {
      dataService
        .get(
          "get_draft_invoices",
          `dpos_opening_entry=${this.dpos_opening_entry.name}`
        )
        .then((res) => {
          this.emitter.emit("openHeld", res.data.message);
        });
    },

    openReturns() {
      this.emitter.emit("openReturns", this.pos_profile.company);
    },

    closePayments() {
      this.emitter.emit("onShowPayment", false);
    },

    async getItem(item) {
      const vm = this;
      await dataService
        .post("get_item_detail", {
          warehouse: this.pos_profile.warehouse,
          doc: this.getInvoiceDoc(),
          price_list: this.pos_profile.price_list,
          item: {
            item_code: item.item_code,
            customer: this.customer,
            doctype: "Sales Invoice",
            name: "New Sales Invoice 1",
            company: this.pos_profile.company,
            conversion_rate: 1,
            qty: item.qty,
            price_list_rate: item.price_list_rate,
            child_docname: "New Sales Invoice Item 1",
            cost_center: this.pos_profile.cost_center,
            currency: this.pos_profile.currency,
            // plc_conversion_rate: 1,
            pos_profile: this.pos_profile.name,
            price_list: this.pos_profile.selling_price_list,
            uom: item.uom,
            tax_category: "",
            transaction_type: "selling",
            update_stock: this.pos_profile.update_stock,
            price_list: this.get_price_list(),
            has_batch_no: item.has_batch_no,
            serial_no: item.serial_no,
            batch_no: item.batch_no,
            is_stock_item: item.is_stock_item,
          },
        })
        .then((res) => {
          const data = res.data.message;
          if (data) {
            if (
              item.has_batch_no &&
              vm.pos_profile.custom_auto_set_batch &&
              !item.batch_no &&
              data.batch_no
            ) {
              item.batch_no = data.batch_no;
              vm.set_batch_qty(item, item.batch_no, false);
            }
            if (data.has_pricing_rule) {
            } else if (
              vm.pos_profile.custom_apply_customer_discount &&
              vm.customer_info.posa_discount > 0 &&
              vm.customer_info.posa_discount <= 100
            ) {
              if (
                item.posa_is_offer == 0 &&
                !item.posa_is_replace &&
                item.posa_offer_applied == 0
              ) {
                if (item.max_discount > 0) {
                  item.discount_percentage =
                    item.max_discount < vm.customer_info.posa_discount
                      ? item.max_discount
                      : vm.customer_info.posa_discount;
                } else {
                  item.discount_percentage = vm.customer_info.posa_discount;
                }
              }
            }
            if (!item.btach_price) {
              if (
                !item.is_free_item &&
                !item.posa_is_offer &&
                !item.posa_is_replace
              ) {
                item.price_list_rate = data.price_list_rate;
              }
            }
            item.last_purchase_rate = data.last_purchase_rate;
            item.projected_qty = data.projected_qty;
            item.reserved_qty = data.reserved_qty;
            item.conversion_factor = data.conversion_factor;
            item.stock_qty = data.stock_qty;
            item.actual_qty = data.actual_qty;
            item.stock_uom = data.stock_uom;
            item.custom_parent_item = this.isCombine
              ? this.parentItemName
              : null;
            (item.has_serial_no = data.has_serial_no),
              (item.has_batch_no = data.has_batch_no),
              vm.calc_item_price(item);
          }
        });
    },

    getCustomerDetails() {
      const vm = this;
      if (this.customer) {
        dataService
          .post("get_customer_info", {
            customer: vm.customer,
          })
          .then((res) => {
            const data = res.data.message;
            if (!res.exc) {
              vm.customer_info = {
                ...data,
              };
            }
            vm.update_price_list();
          });
      }
    },

    get_price_list() {
      let price_list = this.pos_profile.selling_price_list;
      if (this.customer_info && this.pos_profile) {
        const { customer_price_list, customer_group_price_list } =
          this.customer_info;
        const pos_price_list = this.pos_profile.selling_price_list;
        if (customer_price_list && customer_price_list != pos_price_list) {
          price_list = customer_price_list;
        } else if (
          customer_group_price_list &&
          customer_group_price_list != pos_price_list
        ) {
          price_list = customer_group_price_list;
        }
      }
      return price_list;
    },

    update_price_list() {
      let price_list = this.get_price_list();
      if (price_list == this.pos_profile.selling_price_list) {
        price_list = null;
      }
      this.emitter.emit("update_customer_price_list", price_list);
    },
    handleChangeDiscountAmount() {
      const value = Number(this.additional_discount_percentage);
      if (value >= -100 && value <= 100) {
        this.discount_amount = (this.total * value) / 100;
      } else {
        this.additional_discount_percentage = 0;
        this.discount_amount = 0;
      }
    },

    handleDiscountItem(value, item, id, index) {
      if (id === "rate" + index) {
        item.discount_percentage = 0;
        if (value < item.price_list_rate) {
          item.discount_amount = Number(
            (Number(item.price_list_rate) - Number(value)).toFixed(
              this.currency_precision
            )
          );
        } else if (value < 0) {
          item.rate = item.price_list_rate;
          item.discount_amount = 0;
        } else if (value > item.price_list_rate) {
          item.discount_amount = 0;
        }
      } else if (id === "discount_amount" + index) {
        if (value < 0) {
          item.discount_amount = 0;
          item.discount_percentage = 0;
        } else {
          item.rate = Number(item.price_list_rate) - Number(value);
          item.discount_percentage = 0;
        }
      } else if (id === "discount_percentage" + index) {
        if (value < 0) {
          item.discount_amount = 0;
          item.discount_percentage = 0;
        } else {
          item.rate = Number(
            (
              Number(item.price_list_rate) -
              (Number(item.price_list_rate) * Number(value)) / 100
            ).toFixed(this.currency_precision)
          );
          item.discount_amount = Number(
            (Number(item.price_list_rate) - Number(item.rate)).toFixed(
              this.currency_precision
            )
          );
        }
      }
    },

    calc_item_price(item) {
      if (!item.posa_offer_applied) {
        if (item.price_list_rate) {
          item.rate = item.price_list_rate;
        }
      }
      if (item.discount_percentage) {
        item.rate =
          Number(item.price_list_rate) -
          (Number(item.price_list_rate) * Number(item.discount_percentage)) /
            100;
        item.discount_amount = (
          Number(item.price_list_rate) - Number(item.rate)
        ).toFixed(this.currency_precision);
      } else if (item.discount_amount) {
        item.rate = (
          Number(item.price_list_rate) - Number(item.discount_amount)
        ).toFixed(this.currency_precision);
      }
    },

    handleChangeUOM(item, value) {
      const new_uom = item.item_uoms.find((element) => element.uom == value);
      item.conversion_factor = new_uom.conversion_factor;
      item.uom = new_uom.uom;
      if (!item.posa_offer_applied) {
        item.discount_amount = 0;
        item.discount_percentage = 0;
      }
      if (item.btach_price) {
        item.price_list_rate = item.btach_price * new_uom.conversion_factor;
      }
      this.getItem(item);
    },

    handleChangeQTY(item, value) {
      item.qty = value;
      item.stock_qty = item.conversion_factor * value;
    },

    set_serial_no(item) {
      if (!item.has_serial_no) return;
      item.serial_no = "";
      item.serial_no_selected.forEach((element) => {
        item.serial_no += element + "\n";
      });
      item.serial_no_selected_count = item.serial_no_selected.length;
      if (item.serial_no_selected_count != item.stock_qty) {
        this.emitter.emit("show_mesage", {
          text: __(`Selected Serial No QTY is {0} it should be {1}`, [
            item.serial_no_selected_count,
            item.stock_qty,
          ]),
          color: "warning",
        });
      }
    },

    set_batch_qty(item, value, update = true) {
      const batch_no = item.batch_no_data.find(
        (element) => element.batch_no == value
      );
      item.actual_batch_qty = batch_no.batch_qty;
      item.batch_no_expiry_date = batch_no.expiry_date;
      if (batch_no.btach_price) {
        item.btach_price = batch_no.btach_price;
        item.price_list_rate = batch_no.btach_price;
        item.rate = batch_no.btach_price;
      } else if (update) {
        item.btach_price = null;
        this.getItem(item);
      }
    },

    // formtFloat(value) {
    //   value = parseFloat(value);
    //   return value
    //     .toFixed(this.float_precision)
    //     .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    // },

    shortOpenPayment(e) {
      if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.onShowPayment();
      }
    },

    shortDeleteFirstItem(e) {
      if (e.key === "d" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.handleRemoveItem(this.items[0]);
      }
    },

    shortOpenFirstItem(e) {
      if (e.key === "a" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.expanded = [];
        this.expanded.push(this.items[0]);
      }
    },

    shortSelectDiscount(e) {
      if (e.key === "z" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.$refs.discount.focus();
      }
    },

    makeid(length) {
      let result = "";
      const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    checkOfferIsAppley(item, offer) {
      let applied = false;
      const item_offers = JSON.parse(item.posa_offers);
      for (const row_id of item_offers) {
        const exist_offer = this.posa_offers.find((el) => row_id == el.row_id);
        if (exist_offer && exist_offer.offer_name == offer.name) {
          applied = true;
          break;
        }
      }
      return applied;
    },

    handelOffers() {
      const offers = [];
      this.posOffers.forEach((offer) => {
        if (offer.apply_on === "Item Code") {
          const itemOffer = this.getItemOffer(offer);
          if (itemOffer) {
            offers.push(itemOffer);
          }
        } else if (offer.apply_on === "Item Group") {
          const groupOffer = this.getGroupOffer(offer);
          if (groupOffer) {
            offers.push(groupOffer);
          }
        } else if (offer.apply_on === "Brand") {
          const brandOffer = this.getBrandOffer(offer);
          if (brandOffer) {
            offers.push(brandOffer);
          }
        } else if (offer.apply_on === "Transaction") {
          const transactionOffer = this.getTransactionOffer(offer);
          if (transactionOffer) {
            offers.push(transactionOffer);
          }
        }
      });

      this.setItemGiveOffer(offers);
      this.updatePosOffers(offers);
    },

    setItemGiveOffer(offers) {
      // Set item give offer for replace
      offers.forEach((offer) => {
        if (
          offer.apply_on == "Item Code" &&
          offer.apply_type == "Item Code" &&
          offer.replace_item
        ) {
          offer.give_item = offer.item;
          offer.apply_item_code = offer.item;
        } else if (
          offer.apply_on == "Item Group" &&
          offer.apply_type == "Item Group" &&
          offer.replace_cheapest_item
        ) {
          const offerItemCode = this.getCheapestItem(offer).item_code;
          offer.give_item = offerItemCode;
          offer.apply_item_code = offerItemCode;
        }
      });
    },

    getCheapestItem(offer) {
      let itemsRowID;
      if (typeof offer.items === "string") {
        itemsRowID = JSON.parse(offer.items);
      } else {
        itemsRowID = offer.items;
      }
      const itemsList = [];
      itemsRowID.forEach((row_id) => {
        itemsList.push(this.getItemFromRowID(row_id));
      });
      const result = itemsList.reduce(function (res, obj) {
        return !obj.posa_is_replace &&
          !obj.posa_is_offer &&
          obj.price_list_rate < res.price_list_rate
          ? obj
          : res;
      });
      return result;
    },

    getItemFromRowID(row_id) {
      const item = this.items.find((el) => el.posa_row_id == row_id);
      return item;
    },

    checkQtyAnountOffer(offer, qty, amount) {
      let min_qty = false;
      let max_qty = false;
      let min_amt = false;
      let max_amt = false;
      const applys = [];

      if (offer.min_qty || offer.min_qty == 0) {
        if (qty >= offer.min_qty) {
          min_qty = true;
        }
        applys.push(min_qty);
      }

      if (offer.max_qty > 0) {
        if (qty <= offer.max_qty) {
          max_qty = true;
        }
        applys.push(max_qty);
      }

      if (offer.min_amt > 0) {
        if (amount >= offer.min_amt) {
          min_amt = true;
        }
        applys.push(min_amt);
      }

      if (offer.max_amt > 0) {
        if (amount <= offer.max_amt) {
          max_amt = true;
        }
        applys.push(max_amt);
      }
      let apply = false;
      if (!applys.includes(false)) {
        apply = true;
      }
      const res = {
        apply: apply,
        conditions: { min_qty, max_qty, min_amt, max_amt },
      };
      return res;
    },

    checkOfferCoupon(offer) {
      if (offer.coupon_based) {
        const coupon = this.posa_coupons.find(
          (el) => offer.name == el.pos_offer
        );
        if (coupon) {
          offer.coupon = coupon.coupon;
          return true;
        } else {
          return false;
        }
      } else {
        offer.coupon = null;
        return true;
      }
    },

    getItemOffer(offer) {
      let apply_offer = null;
      if (offer.apply_on === "Item Code") {
        if (this.checkOfferCoupon(offer)) {
          this.items.forEach((item) => {
            if (!item.posa_is_offer && item.item_code === offer.item) {
              const items = [];
              if (
                offer.offer === "Item Price" &&
                item.posa_offer_applied &&
                !this.checkOfferIsAppley(item, offer)
              ) {
              } else {
                const res = this.checkQtyAnountOffer(
                  offer,
                  item.stock_qty,
                  item.stock_qty * item.price_list_rate
                );
                if (res.apply) {
                  items.push(item.posa_row_id);
                  offer.items = items;
                  apply_offer = offer;
                }
              }
            }
          });
        }
      }
      return apply_offer;
    },

    getGroupOffer(offer) {
      let apply_offer = null;
      if (offer.apply_on === "Item Group") {
        if (this.checkOfferCoupon(offer)) {
          const items = [];
          let total_count = 0;
          let total_amount = 0;
          this.items.forEach((item) => {
            if (!item.posa_is_offer && item.item_group === offer.item_group) {
              if (
                offer.offer === "Item Price" &&
                item.posa_offer_applied &&
                !this.checkOfferIsAppley(item, offer)
              ) {
              } else {
                total_count += item.stock_qty;
                total_amount += item.stock_qty * item.price_list_rate;
                items.push(item.posa_row_id);
              }
            }
          });
          if (total_count || total_amount) {
            const res = this.checkQtyAnountOffer(
              offer,
              total_count,
              total_amount
            );
            if (res.apply) {
              offer.items = items;
              apply_offer = offer;
            }
          }
        }
      }
      return apply_offer;
    },

    getBrandOffer(offer) {
      let apply_offer = null;
      if (offer.apply_on === "Brand") {
        if (this.checkOfferCoupon(offer)) {
          const items = [];
          let total_count = 0;
          let total_amount = 0;
          this.items.forEach((item) => {
            if (!item.posa_is_offer && item.brand === offer.brand) {
              if (
                offer.offer === "Item Price" &&
                item.posa_offer_applied &&
                !this.checkOfferIsAppley(item, offer)
              ) {
              } else {
                total_count += item.stock_qty;
                total_amount += item.stock_qty * item.price_list_rate;
                items.push(item.posa_row_id);
              }
            }
          });
          if (total_count || total_amount) {
            const res = this.checkQtyAnountOffer(
              offer,
              total_count,
              total_amount
            );
            if (res.apply) {
              offer.items = items;
              apply_offer = offer;
            }
          }
        }
      }
      return apply_offer;
    },
    getTransactionOffer(offer) {
      let apply_offer = null;
      if (offer.apply_on === "Transaction") {
        if (this.checkOfferCoupon(offer)) {
          let total_qty = 0;
          this.items.forEach((item) => {
            if (!item.posa_is_offer && !item.posa_is_replace) {
              total_qty += item.stock_qty;
            }
          });
          const items = [];
          const total_count = total_qty;
          const total_amount = this.total;
          if (total_count || total_amount) {
            const res = this.checkQtyAnountOffer(
              offer,
              total_count,
              total_amount
            );
            if (res.apply) {
              this.items.forEach((item) => {
                items.push(item.posa_row_id);
              });
              offer.items = items;
              apply_offer = offer;
            }
          }
        }
      }
      return apply_offer;
    },

    updatePosOffers(offers) {
      this.emitter.emit("update_pos_offers", offers);
    },

    updateInvoiceOffers(offers) {
      this.posa_offers.forEach((invoiceOffer) => {
        const existOffer = offers.find(
          (offer) => invoiceOffer.row_id == offer.row_id
        );
        if (!existOffer) {
          this.removeApplyOffer(invoiceOffer);
        }
      });
      offers.forEach((offer) => {
        const existOffer = this.posa_offers.find(
          (invoiceOffer) => invoiceOffer.row_id == offer.row_id
        );
        if (existOffer) {
          existOffer.items = JSON.stringify(offer.items);
          if (
            existOffer.offer === "Give Product" &&
            existOffer.give_item &&
            existOffer.give_item != offer.give_item
          ) {
            const item_to_remove = this.items.find(
              (item) => item.posa_row_id == existOffer.give_item_row_id
            );
            if (item_to_remove) {
              const updated_item_offers = offer.items.filter(
                (row_id) => row_id != item_to_remove.posa_row_id
              );
              offer.items = updated_item_offers;
              this.handleRemoveItem(item_to_remove);
              existOffer.give_item_row_id = null;
              existOffer.give_item = null;
            }
            const newItemOffer = this.ApplyOnGiveProduct(offer);
            if (offer.replace_cheapest_item) {
              const cheapestItem = this.getCheapestItem(offer);
              const oldBaseItem = this.items.find(
                (el) => el.posa_row_id == item_to_remove.posa_is_replace
              );
              newItemOffer.qty = item_to_remove.qty;
              if (oldBaseItem && !oldBaseItem.posa_is_replace) {
                oldBaseItem.qty += item_to_remove.qty;
              } else {
                const restoredItem = this.ApplyOnGiveProduct(
                  {
                    given_qty: item_to_remove.qty,
                  },
                  item_to_remove.item_code
                );
                restoredItem.posa_is_offer = 0;
                this.items.unshift(restoredItem);
              }
              newItemOffer.posa_is_offer = 0;
              newItemOffer.posa_is_replace = cheapestItem.posa_row_id;
              const diffQty = cheapestItem.qty - newItemOffer.qty;
              if (diffQty <= 0) {
                newItemOffer.qty += diffQty;
                this.handleRemoveItem(cheapestItem);
                newItemOffer.posa_row_id = cheapestItem.posa_row_id;
                newItemOffer.posa_is_replace = newItemOffer.posa_row_id;
              } else {
                cheapestItem.qty = diffQty;
              }
            }
            this.items.unshift(newItemOffer);
            existOffer.give_item_row_id = newItemOffer.posa_row_id;
            existOffer.give_item = newItemOffer.item_code;
          } else if (
            existOffer.offer === "Give Product" &&
            existOffer.give_item &&
            existOffer.give_item == offer.give_item &&
            (offer.replace_item || offer.replace_cheapest_item)
          ) {
            this.$nextTick(function () {
              const offerItem = this.getItemFromRowID(
                existOffer.give_item_row_id
              );
              const diff = offer.given_qty - offerItem.qty;
              if (diff > 0) {
                const itemsRowID = JSON.parse(existOffer.items);
                const itemsList = [];
                itemsRowID.forEach((row_id) => {
                  itemsList.push(this.getItemFromRowID(row_id));
                });
                const existItem = itemsList.find(
                  (el) =>
                    el.item_code == offerItem.item_code &&
                    el.posa_is_replace != offerItem.posa_row_id
                );
                if (existItem) {
                  const diffExistQty = existItem.qty - diff;
                  if (diffExistQty > 0) {
                    offerItem.qty += diff;
                    existItem.qty -= diff;
                  } else {
                    offerItem.qty += existItem.qty;
                    this.handleRemoveItem(existItem);
                  }
                }
              }
            });
          } else if (existOffer.offer === "Item Price") {
            this.ApplyOnPrice(offer);
          } else if (existOffer.offer === "Grand total") {
            this.ApplyOnTotal(offer);
          }
          this.addOfferToItems(existOffer);
        } else {
          this.applyNewOffer(offer);
        }
      });
    },

    removeApplyOffer(invoiceOffer) {
      if (invoiceOffer.offer === "Item Price") {
        this.RemoveOnPrice(invoiceOffer);
        const index = this.posa_offers.findIndex(
          (el) => el.row_id === invoiceOffer.row_id
        );
        this.posa_offers.splice(index, 1);
      }
      if (invoiceOffer.offer === "Give Product") {
        const item_to_remove = this.items.find(
          (item) => item.posa_row_id == invoiceOffer.give_item_row_id
        );
        const index = this.posa_offers.findIndex(
          (el) => el.row_id === invoiceOffer.row_id
        );
        this.posa_offers.splice(index, 1);
        this.handleRemoveItem(item_to_remove);
      }
      if (invoiceOffer.offer === "Grand total") {
        this.RemoveOnTotal(invoiceOffer);
        const index = this.posa_offers.findIndex(
          (el) => el.row_id === invoiceOffer.row_id
        );
        this.posa_offers.splice(index, 1);
      }
      if (invoiceOffer.offer === "Loyalty Point") {
        const index = this.posa_offers.findIndex(
          (el) => el.row_id === invoiceOffer.row_id
        );
        this.posa_offers.splice(index, 1);
      }
      this.deleteOfferFromItems(invoiceOffer);
    },

    applyNewOffer(offer) {
      if (offer.offer === "Item Price") {
        this.ApplyOnPrice(offer);
      }
      if (offer.offer === "Give Product") {
        let itemsRowID;
        if (typeof offer.items === "string") {
          itemsRowID = JSON.parse(offer.items);
        } else {
          itemsRowID = offer.items;
        }
        if (
          offer.apply_on == "Item Code" &&
          offer.apply_type == "Item Code" &&
          offer.replace_item
        ) {
          const item = this.ApplyOnGiveProduct(offer, offer.item);
          item.posa_is_replace = itemsRowID[0];
          const baseItem = this.items.find(
            (el) => el.posa_row_id == item.posa_is_replace
          );
          const diffQty = baseItem.qty - offer.given_qty;
          item.posa_is_offer = 0;
          if (diffQty <= 0) {
            item.qty = baseItem.qty;
            this.handleRemoveItem(baseItem);
            item.posa_row_id = item.posa_is_replace;
          } else {
            baseItem.qty = diffQty;
          }
          this.items.unshift(item);
          offer.give_item_row_id = item.posa_row_id;
        } else if (
          offer.apply_on == "Item Group" &&
          offer.apply_type == "Item Group" &&
          offer.replace_cheapest_item
        ) {
          const itemsList = [];
          itemsRowID.forEach((row_id) => {
            itemsList.push(this.getItemFromRowID(row_id));
          });
          const baseItem = itemsList.find(
            (el) => el.item_code == offer.give_item
          );
          const item = this.ApplyOnGiveProduct(offer, offer.give_item);
          item.posa_is_offer = 0;
          item.posa_is_replace = baseItem.posa_row_id;
          const diffQty = baseItem.qty - offer.given_qty;
          if (diffQty <= 0) {
            item.qty = baseItem.qty;
            this.handleRemoveItem(baseItem);
            item.posa_row_id = item.posa_is_replace;
          } else {
            baseItem.qty = diffQty;
          }
          this.items.unshift(item);
          offer.give_item_row_id = item.posa_row_id;
        } else {
          const item = this.ApplyOnGiveProduct(offer);
          this.items.unshift(item);
          if (item) {
            offer.give_item_row_id = item.posa_row_id;
          }
        }
      }
      if (offer.offer === "Grand total") {
        this.ApplyOnTotal(offer);
      }
      if (offer.offer === "Loyalty Point") {
        this.emitter.emit("show_mesage", {
          text: __("Loyalty Point Offer Applied"),
          color: "success",
        });
      }

      const newOffer = {
        offer_name: offer.name,
        row_id: offer.row_id,
        apply_on: offer.apply_on,
        offer: offer.offer,
        items: JSON.stringify(offer.items),
        give_item: offer.give_item,
        give_item_row_id: offer.give_item_row_id,
        offer_applied: offer.offer_applied,
        coupon_based: offer.coupon_based,
        coupon: offer.coupon,
      };
      this.posa_offers.push(newOffer);
      this.addOfferToItems(newOffer);
    },

    ApplyOnGiveProduct(offer, item_code) {
      if (!item_code) {
        item_code = offer.give_item;
      }
      const items = this.allItems;
      const item = items.find((item) => item.item_code == item_code);
      if (!item) {
        return;
      }
      const new_item = { ...item };
      new_item.qty = offer.given_qty;
      new_item.stock_qty = offer.given_qty;
      new_item.rate = offer.discount_type === "Rate" ? offer.rate : item.rate;
      new_item.discount_amount =
        offer.discount_type === "Discount Amount" ? offer.discount_amount : 0;
      new_item.discount_percentage =
        offer.discount_type === "Discount Percentage"
          ? offer.discount_percentage
          : 0;
      new_item.discount_amount_per_item = 0;
      new_item.uom = item.uom ? item.uom : item.stock_uom;
      new_item.actual_batch_qty = "";
      new_item.conversion_factor = 1;
      new_item.posa_offers = JSON.stringify([]);
      new_item.posa_offer_applied = 0;
      new_item.posa_is_offer = 1;
      new_item.posa_is_replace = null;
      new_item.take_note = "";
      new_item.posa_delivery_date = "";
      new_item.is_free_item =
        (offer.discount_type === "Rate" && !offer.rate) ||
        (offer.discount_type === "Discount Percentage" &&
          offer.discount_percentage == 0)
          ? 1
          : 0;
      new_item.posa_row_id = this.makeid(20);
      new_item.price_list_rate =
        (offer.discount_type === "Rate" && !offer.rate) ||
        (offer.discount_type === "Discount Percentage" &&
          offer.discount_percentage == 0)
          ? 0
          : item.rate;
      if (
        (!this.pos_profile.custom_auto_set_batch && new_item.has_batch_no) ||
        new_item.has_serial_no
      ) {
        this.expanded.push(new_item);
      }
      this.getItem(new_item);
      return new_item;
    },

    ApplyOnPrice(offer) {
      this.items.forEach((item) => {
        if (offer.items.includes(item.posa_row_id)) {
          const item_offers = JSON.parse(item.posa_offers);
          if (!item_offers.includes(offer.row_id)) {
            if (offer.discount_type === "Rate") {
              item.rate = offer.rate;
            } else if (offer.discount_type === "Discount Percentage") {
              item.discount_percentage += offer.discount_percentage;
            } else if (offer.discount_type === "Discount Amount") {
              item.discount_amount += offer.discount_amount;
            }
            item.posa_offer_applied = 1;
            this.calc_item_price(item);
          }
        }
      });
    },

    RemoveOnPrice(offer) {
      this.items.forEach((item) => {
        const item_offers = JSON.parse(item.posa_offers);
        if (item_offers.includes(offer.row_id)) {
          const originalOffer = this.posOffers.find(
            (el) => el.name == offer.offer_name
          );
          if (originalOffer) {
            if (originalOffer.discount_type === "Rate") {
              item.rate = item.price_list_rate;
            } else if (originalOffer.discount_type === "Discount Percentage") {
              item.discount_percentage -= offer.discount_percentage;
              if (!item.discount_percentage) {
                item.discount_percentage = 0;
                item.discount_amount = 0;
                item.rate = item.price_list_rate;
              }
            } else if (originalOffer.discount_type === "Discount Amount") {
              item.discount_amount -= offer.discount_amount;
            }
            this.calc_item_price(item);
          }
        }
      });
    },

    ApplyOnTotal(offer) {
      if (!offer.name) {
        offer = this.posOffers.find((el) => el.name == offer.offer_name);
      }
      if (
        (!this.discount_percentage_offer_name ||
          this.discount_percentage_offer_name == offer.name) &&
        offer.discount_percentage > 0 &&
        offer.discount_percentage <= 100
      ) {
        this.discount_amount = (
          (Number(this.total) * Number(offer.discount_percentage)) /
          100
        ).toFixed(this.currency_precision);
        this.discount_percentage_offer_name = offer.name;
      }
    },

    RemoveOnTotal(offer) {
      if (
        this.discount_percentage_offer_name &&
        this.discount_percentage_offer_name == offer.offer_name
      ) {
        this.discount_amount = 0;
        this.discount_percentage_offer_name = null;
      }
    },

    addOfferToItems(offer) {
      const offer_items = JSON.parse(offer.items);
      offer_items.forEach((el) => {
        this.items.forEach((exist_item) => {
          if (exist_item.posa_row_id == el) {
            const item_offers = JSON.parse(exist_item.posa_offers);
            if (!item_offers.includes(offer.row_id)) {
              item_offers.push(offer.row_id);
              if (offer.offer === "Item Price") {
                exist_item.posa_offer_applied = 1;
              }
            }
            exist_item.posa_offers = JSON.stringify(item_offers);
          }
        });
      });
    },

    deleteOfferFromItems(offer) {
      const offer_items = JSON.parse(offer.items);
      offer_items.forEach((el) => {
        this.items.forEach((exist_item) => {
          if (exist_item.posa_row_id == el) {
            const item_offers = JSON.parse(exist_item.posa_offers);
            const updated_item_offers = item_offers.filter(
              (row_id) => row_id != offer.row_id
            );
            if (offer.offer === "Item Price") {
              exist_item.posa_offer_applied = 0;
            }
            exist_item.posa_offers = JSON.stringify(updated_item_offers);
          }
        });
      });
    },

    validate_due_date(item) {
      const today = formattedDateNow;
      const parse_today = Date.parse(today);
      const new_date = Date.parse(item.posa_delivery_date);
      if (new_date < parse_today) {
        setTimeout(() => {
          item.posa_delivery_date = today;
        }, 0);
      }
    },
    load_print_page(invoice_name) {
      const print_format =
        this.pos_profile.print_format_for_online ||
        this.pos_profile.print_format;
      const letter_head = this.pos_profile.letter_head || 0;
      const url =
        frappe.urllib.get_base_url() +
        "/printview?doctype=Sales%20Invoice&name=" +
        invoice_name +
        "&trigger_print=1" +
        "&format=" +
        print_format +
        "&no_letterhead=" +
        letter_head;
      const printWindow = window.open(url, "Print");
      printWindow.addEventListener(
        "load",
        function () {
          printWindow.print();
          // printWindow.close();
          // NOTE : uncomoent this to auto closing printing window
        },
        true
      );
    },

    print_draft_invoice() {
      if (!this.pos_profile.custom_allow_print_draft_invoices) {
        this.emitter.emit("show_mesage", {
          text: __(`You are not allowed to print draft invoices`),
          color: "error",
        });
        return;
      }
      let invoice_name = this.invoice_doc?.name;
      frappe.run_serially([
        () => {
          const invoice_doc = this.handleNewInvoice();
          invoice_name = invoice_doc?.name ? invoice_doc?.name : invoice_name;
        },
        () => {
          this.load_print_page(invoice_name);
        },
      ]);
    },
    setDeliveryCharges() {
      const vm = this;
      if (
        !this.pos_profile ||
        !this.customer ||
        !this.pos_profile.custom_use_delivery_charges
      ) {
        this.delivery_charges = [];
        this.delivery_charges_rate = 0;
        this.selcted_delivery_charges = {};
        return;
      }
      this.delivery_charges_rate = 0;
      this.selcted_delivery_charges = {};
      dataService
        .post("get_applicable_delivery_charges", {
          company: this.pos_profile.company,
          pos_profile: this.pos_profile.name,
          customer: this.customer,
        })
        .then((res) => {
          let data = res.data.message;
          if (data) {
            vm.delivery_charges = data;
          }
        });
    },
    // ----------------Customization Code Start ------------------
    deliveryChargesFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      this.text_input = queryText;
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },

    containsOnlyNumbers(str) {
      return /^\d+$/.test(str);
    },
    create_delivery_charge() {
      if (this.containsOnlyNumbers(this.text_input)) {
        const vm = this;
        frappe
          .call({
            method: "posawesome.posawesome.api.posapp.new_charge_delivery",
            args: {
              query_input: this.text_input,
            },
          })
          .then((response) => {
            if (response.message.Error == true) {
              frappe.msgprint("សូមពិនិត្យព័ត៍មាន POS Profile របស់អ្នក!");
            }
            this.setDeliveryCharges();
            vm.selcted_delivery_charges = response.message[0];
            this.update_delivery_charges();
          });
      } else {
        frappe.msgprint("តម្លៃមិនអាចជាអក្ស!");
      }
    },
    update_delivery_charges() {
      if (this.selcted_delivery_charges) {
        this.delivery_charges_rate = this.selcted_delivery_charges.label;
      } else {
        this.delivery_charges_rate = 0;
      }
    },
    // ----------------Customization Code End ------------------
  },

  destroyed() {
    document.removeEventListener("keydown", this.shortOpenPayment);
    document.removeEventListener("keydown", this.shortDeleteFirstItem);
    document.removeEventListener("keydown", this.shortOpenFirstItem);
    document.removeEventListener("keydown", this.shortSelectDiscount);
  },
};
</script>

<style scoped>
.border_line_bottom {
  border-bottom: 1px solid lightgray;
}
.disable-events {
  pointer-events: none;
}
.create_delivery_charge_buton {
  background-color: blue;
  border-radius: 6px;
  color: white;
  padding: 6px 12px;
  margin-left: 10px;
}
.mdi-color {
  color: white !important;
}
</style>
