{
  "locale": {
    "en": "English",
    "kh": "Khmer"
  },
  "nav": {
    "table": "Table",
    "menu": "Menu",
    "logout": "Logout",
    "login": "Login",
    "delivery": "Delivery",
    "payment": "Payment",
    "setting": "Setting",
    "full_screen": "Toggle   Screen",
    "dark_mode": "Toggle Theme",
    "report": "Reports",
    "closing_shift": "Close Shift",
    "dashboard": "Dashboard",
    "sale": "Sale",
    "delete_item": "Delete Item",
    "sale_transactions": "Sale Transactions",
    "total_stock_summary": "Total Stock Summary",
    "qty_on_hand": "Qty On Hand",
    "end_of_day": "End of Day",
    "floor": "Floor",
    "qr_menu": "QR Menu",
    "item": "Item",
    "item_group": "Item Group",
    "menu_group": "Menu Group",
    "kitchen_station": "Kitchen/Drink Station",
    "modifier": "Modifier",
    "stock": "Stock",
    "purchase_invoice": "Purchase Invoice",
    "uom": "UOM",
    "uom_conversion": "UOM Conversion",
    "supplier": "Supplier",
    "supplier_group":  "Supplier Group",
    "user": "User",
    "customer": "Customer",
    "customer_group": "Customer Group"
  },
  "save": "Save",
  "mode_of_payment": "Mode of Payment",
  "do_you_want_logout": "Do you want to Logout?",
  "close_and_logout": "Closing Entry & Logout"
}
