<template>
  <!-- <v-card
      class="selection mx-auto grey lighten-5"
      style="max-height: 75vh; height: 75vh"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="info"
      ></v-progress-linear>
      <v-row class="items px-2 py-1">
        <v-col class="pb-0 mb-2">
          <v-text-field
            dense
            clearable
            autofocus
            outlined
            color="primary"
            :label="frappe._('Search Items')"
            hint="Search by item code, serial number, batch no or barcode"
            background-color="white"
            hide-details
            v-model="dataSearch"
            @keydown.esc="escEvent"
            @keydown.enter="enterEvent"
            ref="dataSearch"
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="pb-0 mb-2" v-if="pos_profile.custom_use_qty_input">
          <v-text-field
            dense
            outlined
            color="primary"
            :label="frappe._('QTY')"
            background-color="white"
            hide-details
            v-model.number="qty"
            type="number"
            @keydown.enter="enterEvent"
            @keydown.esc="escEvent"
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="pb-0 mb-2" v-if="pos_profile.custom_allow_add_new_items_on_new_line">
          <v-checkbox
            v-model="new_line"
            color="accent"
            value="true"
            label="NLine"
            dense
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col cols="12" class="pt-0 mt-0">
          <div fluid class="items" v-if="items_view == 'card'">
            <v-row dense class="overflow-y-auto" style="max-height: 67vh">
              <v-col
                v-for="(item, idx) in filtredItems"
                :key="idx"
                xl="2"
                lg="3"
                md="6"
                sm="6"
                cols="6"
                min-height="50"
              >
                <v-card hover="hover" @click="addItem(item)">
                  <v-img
                    :src="
                      item.image ||
                      '/assets/posawesome/js/posapp/components/pos/placeholder-image.png'
                    "
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.2), rgba(0,0,0,.7)"
                    height="100px"
                  >
                    <v-card-text
                      v-text="item.item_name"
                      class="text-subtitle-2 px-1 pb-2"
                    ></v-card-text>
                  </v-img>
                  <v-card-text class="text--primary pa-1">
                    <div class="text-caption primary--text">
                      {{ formtCurrency(item.rate) || 0 }}
                      {{ item.currency || '' }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div fluid class="items" v-if="items_view == 'list'">
            <div class="my-0 py-0 overflow-y-auto" style="max-height: 65vh">
              <template>
                <v-data-table
                  :headers="getItmesHeaders()"
                  :items="filtredItems"
                  item-key="item_code"
                  class="elevation-1"
                  :items-per-page="itemsPerPage"
                  hide-default-footer
                  @click:row="addItemToCart"
                >
                  <template v-slot:item.rate="{ item }">
                    {{ formtCurrency(item.rate) }}
                  </template>
                  <template v-slot:item.actual_qty="{ item }">
                    {{ formtFloat(item.actual_qty) }}
                  </template>
                </v-data-table>
              </template>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="cards mb-0 mt-3 pa-2 grey lighten-5">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="12">
          <v-select
            :items="items_group"
            :label="frappe._('Items Group')"
            dense
            outlined
            hide-details
            v-model="item_group"
          ></v-select>
        </v-col>
        <v-col cols="3" class="mt-1">
          <v-btn-toggle
            v-model="items_view"
            color="primary"
            group
            dense
            rounded
          >
            <v-btn small value="list">{{ __('List') }}</v-btn>
            <v-btn small value="card">{{ __('Card') }}</v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="4" class="mt-2">
          <v-btn small block color="primary" text @click="show_coupons"
            >{{ couponsCount }} {{ __('Coupons') }}</v-btn
          >
        </v-col>
        <v-col cols="5" class="mt-2">
          <v-btn small block color="primary" text @click="show_offers"
            >{{ offersCount }} {{ __('Offers') }} : {{ appliedOffersCount }}
            {{ __('Applied') }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card> -->
  <div class="w-full space-y-4">
    <div class="grid grid-cols-6 gap-2 items-center">
      <div
        class="relative w-full"
        :class="
          !pos_profile.custom_use_qty_input && !pos_profile.custom_allow_add_new_items_on_new_line
            ? 'col-span-6'
            : !pos_profile.custom_allow_add_new_items_on_new_line || !pos_profile.custom_use_qty_input
            ? 'col-span-5'
            : 'col-span-4'
        "
      >
        <input
          type="text"
          v-model="dataSearch"
          @keydown.esc="escEvent"
          @keydown.enter="enterEvent"
          ref="dataSearch"
          class="w-full px-9 py-[7px] border dark:text-gray-300 bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparente"
          placeholder="Search items..."
        />
        <div class="absolute py-[9px] px-3 top-0 left-0 text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </div>
        <div
          v-if="dataSearch != ''"
          @click="dataSearch = ''"
          class="absolute right-3 top-2.5 text-gray-500 dark:text-gray-400 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
      <div v-if="pos_profile.custom_use_qty_input" class="col-span-1 relative">
        <InputComponent type="number" v-model="qty" />
        <label
          class="absolute -top-1 left-1 px-1 bg-white dark:bg-slate-700 rounded-md leading-3 text-sm font-medium dark:text-gray-100"
          >QTY</label
        >
      </div>

      <div
        v-if="pos_profile.custom_allow_add_new_items_on_new_line"
        class="col-span-1 mt-1 flex items-center space-x-2"
      >
        <input
          id="new_line"
          type="checkbox"
          v-model="new_line"
          class="h-4 w-4 cursor-pointer accent-secondary"
        /><label
          for="new_line"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >New Line</label
        >
      </div>
    </div>
    <div class="flex items-center justify-between space-x-2 w-full">
      <div class="flex items-center space-x-2 w-[88%] sm:w-[93%]">
        <nav
          class="grid grid-flow-col text-center max-w-full overflow-x-auto no-scrollbar text-gray-600 bg-gray-200 dark:text-gray-100 dark:bg-slate-700 rounded-md"
        >
          <a v-for="(item, index) in items_group" :key="item.name">
            <span
              @click="() => handleSelectMenu(item, index)"
              class="flex justify-center cursor-pointer ellipsis py-2 w-40"
              :class="[
                item.name === item_group.name &&
                  'bg-primary rounded-md shadow text-white',
              ]"
              >{{ item.custom_item_group_name }}</span
            >
          </a>
        </nav>
      </div>
      <button
        type="button"
        @click="isList = !isList"
        class="text-sm p-2 bg-gray-200 dark:bg-slate-600 text-gray-800 dark:text-white rounded-md font-bold"
      >
        <svg
          v-if="!isList"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path
            fill-rule="evenodd"
            d="M2.625 6.75a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0A.75.75 0 0 1 8.25 6h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM2.625 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0ZM7.5 12a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12A.75.75 0 0 1 7.5 12Zm-4.875 5.25a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75Z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path
            fill-rule="evenodd"
            d="M3 6a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3V6ZM3 15.75a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-2.25Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3v-2.25Z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div class="relative">
      <SpinComponent :show="loading" />
      <div
        id="infinite-list"
        class="relative mt-2 overflow-auto h-[calc(100vh_-_188px)] lg:h-[calc(100vh_-_209px)] pr-2"
      >
        <div v-show="isList" class="w-full">
          <TableComponent
            :columns="columns"
            :items="renderItems(filtredItems)"
            :onClickRow="(record) => addItem(record)"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'rate'"
                ><span>{{ getCurrencySymbol }} </span
                > {{
                  $n(
                    record.rate,
                    "decimal",
                    `${record.currency === "USD" ? "en" : "kh"}`
                  )
                }}
              </template>
              <template v-if="column.key === 'actual_qty'"
                >{{ record.actual_qty }}
              </template>
            </template>
          </TableComponent>
        </div>
        <div
          v-show="!isList"
          class="grid gap-2 lg:gap-4 grid-cols-3 lg:grid-cols-4"
        >
          <div
            v-for="item in filtredItems"
            :key="item.name"
            class="group cursor-pointer"
            @click="addItem(item)"
          >
            <div
              class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 lg:aspect-h-8 lg:aspect-w-7"
            >
              <img
                :src="item.image ? baseURL + item.image : imageEmpty"
                :alt="item?.imageAlt"
                class="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
            <h3
              class="mt-3 text-1xs lg:text-sm text-gray-700 dark:text-gray-100 ellipsis-line line-clamp-2"
            >
              {{ item.item_name }}
            </h3>
            <p
              class="mt-1 text-1xs lg:text-sm font-medium text-gray-900 dark:text-gray-100"
            ><span>{{ getCurrencySymbol }} </span
              >
               {{
                $n(
                  item.rate,
                  "decimal",
                  `${item.currency === "USD" ? "en" : "kh"}`
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    key: "item_name",
    label: "Name",
    dataIndex: "item_name",
    sort: true,
  },
  {
    key: "custom_item_code",
    label: "Item Code",
    dataIndex: "custom_item_code",
    sort: true,
  },
  {
    key: "rate",
    label: "Rate",
    alignHeader: "end",
    align: "center",
    sort: true,
  },
  {
    key: "actual_qty",
    label: "Available QTY",
    alignHeader: "end",
    align: "center",
    sort: true,
  },
  {
    key: "stock_uom",
    label: "UOM",
    dataIndex: "stock_uom",
    alignHeader: "end",
    align: "center",
    sort: true,
  },
];
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import imageEmpty from "@/assets/image/empty.jpg";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import TableComponent from "@/components/table/TableComponent.vue";
import { renderItems } from "@/utils/globleFunction";
import InputComponent from "@/components/input/InputComponent.vue";
import { baseURL } from "@/utils/baseURL";

export default {
  components: {
    SpinComponent,
    TableComponent,
    InputComponent,
  },
  data: () => ({
    imageEmpty,
    renderItems,
    columns,
    flags: {},
    isList: true,
    item_group: {name: "ALL", custom_item_group_name: "All"},
    items_group: [{name: "ALL", custom_item_group_name: "All"}],
    items: [],
    search: "",
    first_search: "",
    itemsPerPage: 1000,
    offersCount: 0,
    appliedOffersCount: 0,
    couponsCount: 0,
    appliedCouponsCount: 0,
    customer_price_list: null,
    float_precision: 2,
    currency_precision: 2,
    new_line: false,
    baseURL,
    qty: 1,
  }),
  created() {
    this.getAllItems();
    this.geItemsGroups();
    // this.emitter.on("register_pos_profile", (data) => {
    //   this.pos_profile = data.pos_profile;
    // this.get_items();
    // this.geItemsGroups();
    // this.float_precision =
    //   frappe.defaults.get_default('float_precision') || 2;
    // this.currency_precision =
    //   frappe.defaults.get_default('currency_precision') || 2;
    // });
    // this.emitter.on('update_cur_items_details', () => {
    //   this.update_cur_items_details();
    // });
    // this.emitter.on('update_offers_counters', (data) => {
    //   this.offersCount = data.offersCount;
    //   this.appliedOffersCount = data.appliedOffersCount;
    // });
    // this.emitter.on('update_coupons_counters', (data) => {
    //   this.couponsCount = data.couponsCount;
    //   this.appliedCouponsCount = data.appliedCouponsCount;
    // });
    // this.emitter.on('update_customer_price_list', (data) => {
    //   this.customer_price_list = data;
    // });
  },
  computed: {
    ...mapGetters("retail", {
      userProfile: "userProfile",
      allItems: "allItems",
      loading: "loading",
    }),
    ...mapGetters("auth", {
      getCurrencySymbol: "getCurrencySymbol",
    }),
    pos_profile() {
      return this.userProfile.pos_profile;
    },
    filtredItems() {
      this.search = this.get_search(this.first_search);
      let filtred_list = [];
      let filtred_group_list = [];
      if (this.item_group.name != "ALL") {
        filtred_group_list = this.allItems.filter((item) =>
          item.item_group.toLowerCase().includes(this.item_group.name.toLowerCase())
        );
      } else {
        filtred_group_list = this.allItems;
      }
      if (!this.search || this.search.length < 3) {
        if (
          this.pos_profile.custom_show_template_items &&
          this.pos_profile.custom_hide_variants_items
        ) {
          return (filtred_list = filtred_group_list
            .filter((item) => !item.variant_of)
            .slice(0, 50));
        } else {
          return (filtred_list = filtred_group_list.slice(0, 50));
        }
      } else if (this.search) {
        filtred_list = filtred_group_list.filter((item) => {
          let found = false;
          for (let element of item.item_barcode) {
            if (element.barcode == this.search) {
              found = true;
              break;
            }
          }
          return found;
        });
        if (filtred_list.length == 0) {
          filtred_list = filtred_group_list.filter((item) =>
            item.item_code.toLowerCase().includes(this.search.toLowerCase())
          );
          if (filtred_list.length == 0) {
            filtred_list = filtred_group_list.filter((item) =>
              item.item_name.toLowerCase().includes(this.search.toLowerCase())
            );
          }
          if (
            filtred_list.length == 0 &&
            this.pos_profile.custom_search_by_serial_number
          ) {
            filtred_list = filtred_group_list.filter((item) => {
              let found = false;
              for (let element of item.serial_no_data) {
                if (element.serial_no == this.search) {
                  found = true;
                  this.flags.serial_no = null;
                  this.flags.serial_no = this.search;
                  break;
                }
              }
              return found;
            });
          }
        }
      }
      if (
        this.pos_profile.custom_show_template_items &&
        this.pos_profile.custom_hide_variants_items
      ) {
        return filtred_list.filter((item) => !item.variant_of).slice(0, 50);
      } else {
        return filtred_list.slice(0, 50);
      }
    },
    dataSearch: {
      get() {
        return this.first_search;
      },
      set: _.debounce(function (newValue) {
        this.first_search = newValue;
      }, 200),
    },
  },
  mounted() {
    this.scan_barcoud();
    this.$refs.dataSearch.focus();
  },
  watch: {
    filtredItems(data_value) {
      this.getItems(data_value);
    },
    customer_price_list() {
      this.get_items();
    },
    new_line() {
      this.emitter.emit("setNewLine", this.new_line);
    },
  },
  methods: {
    ...mapActions("retail", {
      getAllItems: "getAllItems",
    }),
    getItems(items) {
      dataService
        .post("get_items_details", {
          items_data: items,
        })
        .then((res) => {
          let data = res.data.message;
          if (data) {
            items.forEach((item) => {
              const updated_item = data.find(
                (element) => element.item_code == item.item_code
              );
              item.actual_qty = updated_item.actual_qty;
              item.serial_no_data = updated_item.serial_no_data;
              item.batch_no_data = updated_item.batch_no_data;
              item.item_uoms = updated_item.item_uoms;
            });
          }
        });
    },
    handleSelectMenu(item, index) {
      const nav = document.querySelector("nav");
      const link = document.querySelectorAll("nav a")[index];

      const scrollPosition =
        link.offsetLeft - (nav.offsetWidth - link.offsetWidth) / 2;

      nav.scroll({
        left: scrollPosition,
        behavior: "smooth",
      });
      this.item_group = item;
    },

    geItemsGroups() {
      if (this.pos_profile.item_groups.length > 0) {
        this.pos_profile.item_groups.forEach((element) => {
          if (element.item_group !== "All Item Groups") {
            this.items_group.push(element.item_group);
          }
        });
      } else {
        const vm = this;
        dataService.get("get_items_groups").then((res) => {
          let data = res.data.message;
          if (data) {
            data.forEach((element) => {
              vm.items_group.push(element);
            });
          }
        });
      }
    },
    addItem(item) {
      if (item.has_variants) {
        this.emitter.emit("open_variants_model", item, this.items);
      } else {
        if (!item.qty || item.qty === 1) {
          item.qty = Math.abs(this.qty);
        }
        this.emitter.emit("addItemToCart", item);
        this.qty = 1;
      }
    },
    enterEvent() {
      if (!this.filtredItems.length || !this.first_search) {
        return;
      }
      const qty = this.getItemQty(this.first_search);
      const new_item = { ...this.filtredItems[0] };
      new_item.qty = Number(qty);
      new_item.item_barcode.forEach((element) => {
        if (this.search == element.barcode) {
          new_item.uom = element.posa_uom;
        }
      });
      if (this.flags.serial_no) {
        new_item.to_set_serial_no = this.flags.serial_no;
      }
      this.addItem(new_item);
      this.search = null;
      this.first_search = null;
      this.dataSearch = null;
      this.flags.serial_no = null;
      this.qty = 1;
      this.$refs.dataSearch.focus();
    },
    getItemQty(first_search) {
      let scal_qty = Math.abs(this.qty);
      if (first_search.startsWith(this.pos_profile.custom_scale_barcode_start_with)) {
        let pesokg1 = first_search.substr(7, 5);
        let pesokg;
        if (pesokg1.startsWith("0000")) {
          pesokg = "0.00" + pesokg1.substr(4);
        } else if (pesokg1.startsWith("000")) {
          pesokg = "0.0" + pesokg1.substr(3);
        } else if (pesokg1.startsWith("00")) {
          pesokg = "0." + pesokg1.substr(2);
        } else if (pesokg1.startsWith("0")) {
          pesokg =
            pesokg1.substr(1, 1) + "." + pesokg1.substr(2, pesokg1.length);
        } else if (!pesokg1.startsWith("0")) {
          pesokg =
            pesokg1.substr(0, 2) + "." + pesokg1.substr(2, pesokg1.length);
        }
        scal_qty = pesokg;
      }
      return scal_qty;
    },
    get_search(first_search) {
      let search_term = "";
      if (
        first_search &&
        first_search.startsWith(this.pos_profile.custom_scale_barcode_start_with)
      ) {
        search_term = first_search.substr(0, 7);
      } else {
        search_term = first_search;
      }
      return search_term;
    },
    escEvent() {
      this.search = null;
      this.first_search = null;
      this.qty = 1;
      this.$refs.dataSearch.focus();
    },
    update_cur_items_details() {
      this.getItems(this.filtredItems);
    },
    scan_barcoud() {
      // const vm = this;
      // onScan.attachTo(document, {
      //   suffixKeyCodes: [],
      //   keyCodeMapper: function (oEvent) {
      //     oEvent.stopImmediatePropagation();
      //     return onScan.decodeKeyEvent(oEvent);
      //   },
      //   onScan: function (sCode) {
      //     setTimeout(() => {
      //       vm.trigger_onscan(sCode);
      //     }, 300);
      //   },
      // });
    },
    trigger_onscan(sCode) {
      if (this.filtredItems.length == 0) {
        this.emitter.emit("show_mesage", {
          text: `No Item has this barcode "${sCode}"`,
          color: "error",
        });
        frappe.utils.play_sound("error");
      } else {
        this.enterEvent();
        this.dataSearch = null;
        this.search = null;
      }
    },
    formtCurrency(value) {
      value = parseFloat(value);
      return value
        .toFixed(this.currency_precision)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    formtFloat(value) {
      value = parseFloat(value);
      return value
        .toFixed(this.float_precision)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
  },
};
</script>
